import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../context/UserContext";
import Loader from "./Loader";
import { ReactSlackChat } from "react-slack-chat";
import { NavLink, useHistory } from "react-router-dom";
import logo from "../images/cf_white (Custom).png";
import {
  faHome,
  faCalendarDays,
  faFile,
  faComments,
  faCloudArrowUp,
  faGraduationCap,
  faSignal,
  faEnvelope,
  faGaugeHigh,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";

const Header = (props) => {
  // store props value in local storage
  const [userContext, setUserContext] = useContext(UserContext);
  //const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const history = useHistory();

  useEffect(() => {
    // fetch only when user details are not present
    // console.log("Reached fetch mail in the Header")
    // fetchMail();
  }, []);

  const logoutHandler = () => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "users/logout", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userContext.token}`,
      },
    }).then(async (response) => {
      setUserContext((oldValues) => {
        return { ...oldValues, details: undefined, token: null };
      });
      window.localStorage.setItem("logout", Date.now());

      // navigate back to home
      history.push({
        pathname: "/",
      });
    });
  };

  // const fetchMail = useCallback(() => {
  //     //console.log("Called fetch mail")

  //     // const url = process.env.REACT_APP_API_ENDPOINT + 'messaging/message/count'
  //     // //console.log(userContext.details.firstName + " " + userContext.details.lastName)
  //     // axios({
  //     //     url: url,
  //     //     params: { recipientName: userContext.details.firstName + " " + userContext.details.lastName },
  //     //     method: 'GET',
  //     // })
  //     //     .then((response) => {

  //     //         //console.log(response.data)
  //     //         // do something with the data
  //     //        // setUnreadMessagesCount(response.data);

  //     //     })
  //     //     .catch((err) => {
  //     //         console.log(err.message);
  //     //     });

  // })
  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };
  const navDropdownTitle = (
    <FontAwesomeIcon icon={faFile}> Dropdown </FontAwesomeIcon>
  );
  return userContext.details === null ? (
    "Error Loading User details"
  ) : !userContext.details ? (
    <Loader />
  ) : (
    <div>
      <header className="p- bg-dark text-white bannerStretch">
        <div className="container-fluid">
          <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
            <a className="navbar-brand">
              <img src={logo} className="headerImage" alt="" />
            </a>

            <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
              <li>
                <NavLink
                  to="/"
                  exact
                  className="nav-link px-3 text-white smaller-header-text"
                >
                  <FontAwesomeIcon icon={faHome} /> &nbsp;Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/resources"
                  className="nav-link px-2 text-white smaller-header-text"
                >
                  <FontAwesomeIcon icon={faGraduationCap} /> &nbsp;Resources
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/schedules"
                  className="nav-link px-2 text-white smaller-header-text"
                >
                  <FontAwesomeIcon icon={faCalendarDays} /> &nbsp;Schedules
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/files"
                  className="nav-link px-2 text-white smaller-header-text"
                >
                  <FontAwesomeIcon icon={faFile} /> &nbsp;Files
                </NavLink>
              </li>

              {/* <li>
                  <NavLink
                    to="/student-data-files"
                    className="nav-link px-2 smaller-header-text text-white"
                  >
                    <FontAwesomeIcon icon={faFile} /> &nbsp;Student Data Files
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/student-card-files"
                    className="nav-link px-2 smaller-header-text text-white"
                  >
                    <FontAwesomeIcon icon={faFile} /> &nbsp;Student Card Files
                  </NavLink>
                </li> */}

              <li>
                <NavLink
                  to="/feedback"
                  className="nav-link px-2 text-white smaller-header-text"
                >
                  <FontAwesomeIcon icon={faComments} /> &nbsp;Feedback
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/inbox"
                  className="nav-link px-2 text-white smaller-header-text"
                >
                  <FontAwesomeIcon icon={faEnvelope} /> &nbsp;Messaging
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/transfer-intro"
                  className="nav-link px-2 text-white smaller-header-text"
                >
                  <FontAwesomeIcon icon={faCloudArrowUp} /> &nbsp;Transfer Work
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/management-stats"
                  className="nav-link px-2 text-white smaller-header-text"
                >
                  <FontAwesomeIcon icon={faSignal} /> &nbsp;Scores
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/timesheets"
                  className="nav-link px-2 text-white smaller-header-text"
                >
                  <FontAwesomeIcon icon={faGaugeHigh} /> &nbsp;Timesheets
                </NavLink>
              </li>
            </ul>

            <div className="text-end">
              <Badge
                badgeContent={localStorage.getItem("unreadMessageCount")}
                color="secondary"
                className="mailIcon"
              >
                <NavLink to="/inbox">
                  <MailIcon className="text-white" />
                </NavLink>
              </Badge>
              <button
                type="button"
                className="btn btn-outline-primary"
                text="Logout"
                onClick={logoutHandler}
                minimal
                intent="primary"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
export default Header;
