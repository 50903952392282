function FilemailWidget() {
  const script = document.createElement("script");

  script.src = "https://www.filemail.com/widgets/embed/uploader";
  script.async = true;

  document.body.appendChild(script);
}

export default FilemailWidget;
