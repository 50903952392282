import React, {
  useCallback,
  useContext,
  useEffect,
  Component,
  useState,
} from "react";
import Card from "@mui/material/Card";
import { CardContent } from "@mui/material";
import { Slider } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Rating, { IconContainerProps } from "@mui/material/Rating";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { v4 as uuidv4 } from "uuid";
import dayjs, { Dayjs } from "dayjs";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Header from "../components/Header";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";
import Alert from "@mui/material/Alert";
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone";
import { purple } from "@mui/material/colors";

const EnterFeedback = () => {
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const initialFormData = Object.freeze({
    dateOfFeedback: "",
    editorName: "",
    photographerName: "",
    schoolName: "",
    shootDate: "",
    jobType: "",
    timeTaken: "",
    additionalComments: "",
    fileHandling: 0,
    dataSlates: 0,
    dataSlatesIgnored: false,
    posingCropping: 0,
    backgroundLighting: 0,
    glassesPhotoshop: 0,
    overallJobRating: 0,
  });

  const [formData, updateFormData] = React.useState(initialFormData);
  const [ratingValue, setRatingValue] = React.useState(0);
  const [feedbackDate, setFeedbackDate] = React.useState();
  const [shootDate, setShootDate] = React.useState();
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [controlDisabled, setControlDisabled] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    // fetch only when user details are not present
    fetchPhotographerNames();
  }, []);

  const handleChange = (e) => {
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]: e.target.value,
    });
  };

  const changeValue = (event, value) => {
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [event.target.name]: event.target.value,
    });
  };

  const handleFeedbackDateChange = (date) => {
    setFeedbackDate(date);
    console.log(date);

    //let dateString = new Date(date).toUTCString();
    //dateString = dateString.split(" ").slice(0, 4).join(" ");

    updateFormData({
      ...formData,

      // Trimming any whitespace
      dateOfFeedback: date,
    });
  };

  const handleShootDateChange = (date) => {
    setShootDate(date);
    console.log(date);

    //let dateString = new Date(date).toUTCString();
    //dateString = dateString.split(" ").slice(0, 4).join(" ");

    updateFormData({
      ...formData,

      // Trimming any whitespace
      shootDate: date,
    });
  };

  const handleCheckBoxClick = (e) => {
    if (controlDisabled === false) setControlDisabled(true);
    else setControlDisabled(false);

    // get the check box value
    let isChecked = e.target.checked;

    updateFormData({
      ...formData,

      // Trimming any whitespace
      dataSlatesIgnored: isChecked,
    });
  };

  const handleSubmit = useCallback(() => {
    // e.preventDefault()
    let validationPassed = false;

    if (formData.dateOfFeedback === "") {
      setError("Please enter the date of feedback");
    } else if (formData.shootDate === "") {
      setError("Please enter the date of the shoot");
    } else if (formData.editorName === "") {
      setError("Please fill out the editor's name");
    } else if (formData.photographerName === "") {
      setError("Please choose a photographer's name");
    } else if (formData.schoolName === "") {
      setError("Please enter the name of the school");
    } else if (formData.jobType === "") {
      setError("Please enter the job type");
    } else if (formData.timeTaken === "") {
      setError("Please enter the time taken");
    } else if (formData.additionalComments === "") {
      setError(
        "Please extend your feedback by writing something in the additional comments section"
      );
    } else if (ratingValue === 0) {
      setError(
        "Please select one of the smiley faces above to submit an overall rating"
      );
    }

    if (
      (formData.dateOfFeedback !== "") &
      (formData.shootDate !== "") &
      (formData.editorName !== "") &
      (formData.photographerName !== "") &
      (formData.schoolName !== "") &
      (formData.jobType !== "") &
      (formData.timeTaken !== "") &
      (formData.additionalComments !== "") &
      (ratingValue !== 0)
    ) {
      setError("");
      validationPassed = true;
    }
    //console.log(formData);
    // ... submit to API or something

    if (validationPassed === true) {
      const url = process.env.REACT_APP_API_ENDPOINT + "feedback/record/add";
      axios.post(url, formData, {}).then((res) => {
        // console.log(res.data)
      });
      setIsSuccessfullySubmitted(true);
      // reset the form
      //e.target.reset();
      updateFormData({
        dateOfFeedback: "",
        editorName: "",
        photographerName: "",
        schoolName: "",
        shootDate: "",
        jobType: "",
        timeTaken: "",
        additionalComments: "",
        fileHandling: "",
        dataSlates: "",
        dataSlatesIgnored: false,
        posingCropping: "",
        backgroundLighting: "",
        glassesPhotoshop: "",
        overallJobRating: "",
      });

      setRatingValue("");
      setShootDate("");
      setFeedbackDate("");
    }
  }, [formData]);

  const fetchPhotographerNames = (e) => {
    const url =
      process.env.REACT_APP_API_ENDPOINT + "photographers/list-photographers";
    //console.log(userContext.details.firstName + " " + userContext.details.lastName)
    axios({
      url: url,
      method: "GET",
    })
      .then((response) => {
        //  console.log(response.data)
        setData(response.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  function valuetext(value: number) {
    return `${value}`;
  }

  const styles = {
    display: "table-cell",
    width: "80px",
    textAlign: "center",
    verticalAlign: "middle",
    border: "5px solid white",
  };

  const filesPoor = (
    <span className="medText">
      <h6>Poor</h6>
      <ul>
        <li>No effort has been made to take onboard previous feedback</li>
        <li>Groups are not in folders</li>
        <li>Staff are not in seperate folders</li>
        <li>Not edited down to three photos</li>
        <li>Images are missing or the lab had to chase up work</li>
      </ul>
    </span>
  );

  const filesNeedsImprovement = (
    <span className="medText">
      <h6>Needs Improving</h6>
      <ul>
        <li>
          Some effort has been made to act on feedback, but problems remain
        </li>
        <li>Groups are not in folders</li>
        <li>Staff are not in seperate folders</li>
        <li>On the whole edited down to three photos</li>
        <li>No images are missing</li>
      </ul>
    </span>
  );

  const filesGood = (
    <span className="medText">
      <h6>Good</h6>
      <ul>
        <li>Significant effort made to fix any mistakes</li>
        <li>Groups are in folders but some errors</li>
        <li>Staff are in seperate folders but some errors</li>
        <li>Everything edited down to three photos</li>
        <li>No images are missing</li>
      </ul>
    </span>
  );

  const filesOutstanding = (
    <span className="medText">
      <h6>Outstanding</h6>
      <ul>
        <li>No mistakes in this category - awesome :)</li>
        <li>Groups are in folders with no errors</li>
        <li>Staff are in seperate folders with no errors</li>
        <li>Everything edited down to three photos</li>backgrounds
        <li>No images are missing</li>
      </ul>
    </span>
  );

  const dataPoor = (
    <span className="medText">
      <h6>Poor</h6>
      <ul>
        <li>No effort has been made to take onboard previous feedback</li>
        <li>All slates missing</li>
        <li>
          Majority of the shoot was misscanned, or the wrong data was used
        </li>
        <li>Data and/or cards were forgotten or not used</li>
        <li>Many cards shot out of order</li>
      </ul>
    </span>
  );

  const dataNeedsImprovement = (
    <span className="medText">
      <h6>Needs Improving</h6>
      <ul>
        <li>
          Some effort has been made to act on feedback, but problems remain
        </li>
        <li>A lot of slates are missing or incorrect</li>
        <li>
          A significant portion of the shoot could have been scanned better
        </li>
        <li>Some cards shot out of order</li>
      </ul>
    </span>
  );

  const dataGood = (
    <span className="medText">
      <h6>Good</h6>
      <ul>
        <li>Significant effort made to fix any mistakes</li>
        <li>Slates are present and correct</li>
        <li>Some minor shoot misscans</li>
        <li>No cards shot out of order</li>
      </ul>
    </span>
  );

  const dataOutstanding = (
    <span className="medText">
      <h6>Outstanding</h6>
      <ul>
        <li>No mistakes in this category - awesome :)</li>
        <li>Slates are present and correct</li>
        <li>All cards scanned correctly</li>
        <li>No cards shot out of order</li>
      </ul>
    </span>
  );

  const posingPoor = (
    <span className="medText">
      <h6>Poor</h6>
      <ul>
        <li>No effort has been made to take onboard previous feedback</li>
        <li>Subjects are not in the frame</li>
        <li>There are too many add-ins/cluster size is too high</li>
        <li>Head heights are inconsistent across the entire shoot</li>
        <li>The third pose crop is not acceptable or hasn't been done</li>
      </ul>
    </span>
  );

  const posingNeedsImprovement = (
    <span className="medText">
      <h6>Needs Improving</h6>
      <ul>
        <li>
          Some effort has been made to act on feedback, but problems remain
        </li>
        <li>Some subjects are not in the frame, but overall it is okay</li>
        <li>There some add-ins/cluster size needs to be worked on in future</li>
        <li>Head heights are sometimes inconsistent</li>
        <li>The third pose crop is not acceptable</li>
      </ul>
      const [value, setValue] = React.useState(dayjs("2014-08-18T21:11:54"));
    </span>
  );

  const posingGood = (
    <span className="medText">
      <h6>Good</h6>
      <ul>
        <li>Significant effort made to fix any mistakes</li>
        <li>Subjects are in the frame and the posing is mostly good</li>
        <li>Cluster sizes and add ins are acceptable (if applicable)</li>
        <li>Head heights consistent with one or two mistakes</li>
        <li>The third pose crop is acceptable</li>
      </ul>
    </span>
  );

  const posingOutstanding = (
    <span className="medText">
      <h6>Outstanding</h6>
      <ul>
        <li>No mistakes in this category - awesome :)</li>
        <li>Subjects are in the frame and the posing is outstanding</li>
        <li>Cluster sizes and add ins are well thought out (if applicable)</li>
        <li>Head heights consistent with zero mistakes</li>
        <li>The third pose crop is outstanding</li>
      </ul>
    </span>
  );

  const backgroundsPoor = (
    <span className="medText">
      <h6>Poor</h6>
      <ul>
        <li>No effort has been made to take onboard previous feedback</li>
        <li>The majority of the backgrounds are yellow</li>
        <li>There are halos or other light spills throughout the shoot</li>
        <li>The corners of the backdrops are showing throughout the shoot</li>
        <li>There is overspill on sibling/group photographs</li>
        <li>
          There are dirt marks or creases on the background in every photograph
        </li>
        <li>Photos are out of focus</li>
      </ul>
    </span>
  );

  const backgroundsNeedsImprovement = (
    <span className="medText">
      <h6>Needs Improving</h6>
      <ul>
        <li>
          Some effort has been made to act on feedback, but problems remain
        </li>
        <li>
          Some of the backgrounds are yellow, but some effort was made to fix
          this during the shoot
        </li>
        <li>There are halos or other light spills occasionally</li>
        <li>The corners of the backdrops are showing on some photographs</li>
        <li>There is overspill on sibling/group photographs</li>
        <li>
          There are dirt marks or creases on the background in some or all of
          the photographs
        </li>
      </ul>
    </span>
  );

  const backgroundsGood = (
    <span className="medText">
      <h6>Good</h6>
      <ul>
        <li>Significant effort made to fix any mistakes</li>
        <li>Most of the backgrounds are acceptable/good</li>
        <li>There are no halos or other light spills</li>
        <li>No visibile corners showing in the majority of the photographs</li>
        <li>There is little overspill on sibling/group photographs</li>
        <li>There are no dirt marks or creases on the background</li>
      </ul>
    </span>
  );

  const backgroundsOutstanding = (
    <span className="medText">
      <h6>Outstanding</h6>
      <ul>
        <li>No mistakes in this category - awesome :)</li>
        <li>All of the backgrounds are lit correctly</li>
        <li>There are no halos or other light spills</li>
        <li>No visibile corners showing</li>
        <li>There is no overspill on sibling/group photographs</li>
        <li>There are no dirt marks or creases on the background</li>
      </ul>
    </span>
  );

  const glassesPoor = (
    <span className="medText">
      <h6>Poor</h6>
      <ul>
        <li>No effort has been made to take onboard previous feedback</li>
        <li>
          Glasses flare present throughout the shoot that cannot be corrected
          (over pupils/corners)
        </li>
        <li>
          Floors/Backgrounds/Creases needed to be edited on all photographs
        </li>
        <li>Overspill on sibling/group photographs required photoshop work</li>
        <li>Too many head swaps presented through the shoot</li>
      </ul>
    </span>
  );

  const glassesNeedsImprovement = (
    <span className="medText">
      <h6>Needs Improving</h6>
      <ul>
        <li>
          Some effort has been made to act on feedback, but problems remain
        </li>
        <li>
          Some glasses flare present on a few photographs but this can be
          corrected
        </li>
        <li>
          Floors/Backgrounds/Creases needed to be edited on some of the
          photographs
        </li>
        <li>Overspill on sibling/group photographs required photoshop work</li>
        <li>Some head swaps that could have been avoided</li>
      </ul>
    </span>
  );

  const glassesGood = (
    <span className="medText">
      <h6>Good</h6>
      <ul>
        <li>Significant effort made to fix any mistakes</li>
        <li>Minor glasses flare present on very few photographs</li>
        <li>
          Floors/Backgrounds/Creases needed to be edited on very few of the
          photographs
        </li>
        <li>
          No overspill on sibling/group photographs required photoshop work
        </li>
        <li>No head swaps needed</li>
      </ul>
    </span>
  );

  const glassesOutstanding = (
    <span className="medText">
      <h6>Outstanding</h6>
      <ul>
        <li>No mistakes in this category - awesome :)</li>
        <li>No glasses flare throughout the shoot</li>
        <li>No Floors/Backgrounds/Creases needed to be edited</li>
        <li>
          No overspill on sibling/group photographs required photoshop work
        </li>
        <li>No head swaps needed</li>
      </ul>
    </span>
  );

  const marksFileHandling = [
    {
      value: 0,
      label: (
        <Tooltip
          placement="bottom"
          overlay={filesPoor}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <a href="#" style={styles}>
            Poor
          </a>
        </Tooltip>
      ),
    },
    {
      value: 30,
      label: (
        <Tooltip
          placement="bottom"
          overlay={filesNeedsImprovement}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <a href="#" style={styles}>
            Needs Improving
          </a>
        </Tooltip>
      ),
    },
    {
      value: 60,
      label: (
        <Tooltip
          placement="bottom"
          overlay={filesGood}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <a href="#" style={styles}>
            Good
          </a>
        </Tooltip>
      ),
    },
    {
      value: 100,
      label: (
        <Tooltip
          placement="bottom"
          overlay={filesOutstanding}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <a href="#" style={styles}>
            Outstanding
          </a>
        </Tooltip>
      ),
    },
  ];

  const marksDataSlates = [
    {
      value: 0,
      label: (
        <Tooltip
          placement="bottom"
          overlay={dataPoor}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <a href="#" style={styles}>
            Poor
          </a>
        </Tooltip>
      ),
    },
    {
      value: 30,
      label: (
        <Tooltip
          placement="bottom"
          overlay={dataNeedsImprovement}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <a href="#" style={styles}>
            Needs Improving
          </a>
        </Tooltip>
      ),
    },
    {
      value: 60,
      label: (
        <Tooltip
          placement="bottom"
          overlay={dataGood}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <a href="#" style={styles}>
            Good
          </a>
        </Tooltip>
      ),
    },
    {
      value: 100,
      label: (
        <Tooltip
          placement="bottom"
          overlay={dataOutstanding}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <a href="#" style={styles}>
            Outstanding
          </a>
        </Tooltip>
      ),
    },
  ];

  const marksPosingCropping = [
    {
      value: 0,
      label: (
        <Tooltip
          placement="bottom"
          overlay={posingPoor}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <a href="#" style={styles}>
            Poor
          </a>
        </Tooltip>
      ),
    },
    {
      value: 30,
      label: (
        <Tooltip
          placement="bottom"
          overlay={posingNeedsImprovement}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <a href="#" style={styles}>
            Needs Improving
          </a>
        </Tooltip>
      ),
    },
    {
      value: 60,
      label: (
        <Tooltip
          placement="bottom"
          overlay={posingGood}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <a href="#" style={styles}>
            Good
          </a>
        </Tooltip>
      ),
    },
    {
      value: 100,
      label: (
        <Tooltip
          placement="bottom"
          overlay={posingOutstanding}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <a href="#" style={styles}>
            Outstanding
          </a>
        </Tooltip>
      ),
    },
  ];

  const marksBackgroundLighting = [
    {
      value: 0,
      label: (
        <Tooltip
          placement="bottom"
          overlay={backgroundsPoor}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <a href="#" style={styles}>
            Poor
          </a>
        </Tooltip>
      ),
    },
    {
      value: 30,
      label: (
        <Tooltip
          placement="bottom"
          overlay={backgroundsNeedsImprovement}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <a href="#" style={styles}>
            Needs Improving
          </a>
        </Tooltip>
      ),
    },
    {
      value: 60,
      label: (
        <Tooltip
          placement="bottom"
          overlay={backgroundsGood}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <a href="#" style={styles}>
            Good
          </a>
        </Tooltip>
      ),
    },
    {
      value: 100,
      label: (
        <Tooltip
          placement="bottom"
          overlay={backgroundsOutstanding}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <a href="#" style={styles}>
            Outstanding
          </a>
        </Tooltip>
      ),
    },
  ];

  const marksGlassesPhotoshop = [
    {
      value: 0,
      label: (
        <Tooltip
          placement="bottom"
          overlay={glassesPoor}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <a href="#" style={styles}>
            Poor
          </a>
        </Tooltip>
      ),
    },
    {
      value: 30,
      label: (
        <Tooltip
          placement="bottom"
          overlay={glassesNeedsImprovement}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <a href="#" style={styles}>
            Needs Improving
          </a>
        </Tooltip>
      ),
    },
    {
      value: 60,
      label: (
        <Tooltip
          placement="bottom"
          overlay={glassesGood}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <a href="#" style={styles}>
            Good
          </a>
        </Tooltip>
      ),
    },
    {
      value: 100,
      label: (
        <Tooltip
          placement="bottom"
          overlay={glassesOutstanding}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <a href="#" style={styles}>
            Outstanding
          </a>
        </Tooltip>
      ),
    },
  ];

  const StyledRating = styled(Rating)(({ theme }) => ({
    "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
      color: theme.palette.action.disabled,
    },
  }));

  const customIcons: {
    [index: string]: {
      icon: React.ReactElement,
      label: string,
    },
  } = {
    1: {
      icon: (
        <SentimentVeryDissatisfiedIcon
          style={{ width: "50px", height: "50px" }}
          color="error"
        />
      ),
      label: "Very Dissatisfied",
    },
    2: {
      icon: (
        <SentimentDissatisfiedIcon
          style={{ width: "50px", height: "50px" }}
          color="error"
        />
      ),
      label: "Dissatisfied",
    },
    3: {
      icon: (
        <SentimentSatisfiedIcon
          style={{ width: "50px", height: "50px" }}
          color="warning"
        />
      ),
      label: "Neutral",
    },
    4: {
      icon: (
        <SentimentSatisfiedAltIcon
          style={{ width: "50px", height: "50px" }}
          color="success"
        />
      ),
      label: "Satisfied",
    },
    5: {
      icon: (
        <SentimentVerySatisfiedIcon
          style={{ width: "50px", height: "50px" }}
          color="success"
        />
      ),
      label: "Very Satisfied",
    },
  };

  function IconContainer(props: IconContainerProps) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }

  return (
    <div>
      <Header></Header>
      <div class="container-fluid body">
        <div class="row">
          <div class="col-sm">
            <div class="feedbackContainer">
              <h1>Leave Feedback</h1>

              <br></br>
              <form>
                <div class="form-group">
                  <label for="exampleInputEmail1">Date of Feedback</label>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={feedbackDate}
                    onChange={(date: Date) => handleFeedbackDateChange(date)}
                  />
                </div>
                <br></br>
                <div class="form-group">
                  <label for="exampleInputEmail1">Shoot Date</label>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={shootDate}
                    onChange={(date: Date) => handleShootDateChange(date)}
                  />
                </div>
                <br></br>
                <div class="form-group">
                  <label for="exampleInputEmail1">Editor Name</label>
                  <input
                    type="text"
                    class="form-control"
                    name="editorName"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    onChange={handleChange}
                    value={formData.editorName}
                  />
                </div>
                <br></br>
                <div class="form-group">
                  <label for="exampleInputEmail1">Photographer Name</label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <select
                    name="photographerName"
                    onChange={handleChange}
                    value={formData.photographerName}
                    className="form-control"
                  >
                    <option disabled={false} id="test">
                      --Choose a photographer--
                    </option>
                    {data.map(function (item) {
                      // return <option key={item._id} value={item.firstName}></option>
                      return (
                        <option
                          key={item._id}
                          value={item.firstName + " " + item.lastName}
                        >
                          {item.firstName} {item.lastName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <br></br>

                <div class="form-group">
                  <label for="exampleInputEmail1">School Name</label>
                  <input
                    type="text"
                    class="form-control"
                    name="schoolName"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    onChange={handleChange}
                    value={formData.schoolName}
                  />
                </div>
                <br></br>

                <div class="form-group">
                  <label for="exampleInputEmail1">Job Type</label>
                  <input
                    type="text"
                    class="form-control"
                    name="jobType"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    onChange={handleChange}
                    value={formData.jobType}
                  />
                </div>
                <br></br>
                <div class="form-group">
                  <label for="exampleInputEmail1">Time Taken</label>
                  <input
                    type="text"
                    class="form-control"
                    name="timeTaken"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    onChange={handleChange}
                    value={formData.timeTaken}
                  />
                </div>
                <br></br>
                <div className="form-group ">
                  <label for="exampleInputEmail1">Additional Comments</label>
                  <textarea
                    type="text"
                    class="form-control"
                    name="additionalComments"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    rows="5"
                    onChange={handleChange}
                    value={formData.additionalComments}
                  />
                </div>
                <br></br>
              </form>

              <h6 className="">
                <strong>Overall Score</strong>
                <div>
                  <br></br>
                  <StyledRating
                    name="overallJobRating"
                    value={ratingValue}
                    IconContainerComponent={IconContainer}
                    getLabelText={(value: number) => customIcons[value].label}
                    highlightSelectedOnly
                    sx={{
                      fontSize: "10rem",
                    }}
                    onChange={(event, newValue) => {
                      setRatingValue(newValue);
                      handleChange(event, ratingValue);
                    }}
                  />{" "}
                </div>
              </h6>
            </div>
          </div>
          <div class="col-1"></div>

          <div class="col-sm">
            <br></br>
            <Card>
              <CardContent>
                <ErrorTwoToneIcon
                  sx={{ color: purple[800], fontSize: 38 }}
                ></ErrorTwoToneIcon>
                &nbsp;&nbsp; Please ensure all feedback is constructive and
                aimed at improving our work processes as a team.
              </CardContent>
            </Card>
            <div className="feedbackControls">
              <br></br>
              <p>
                <strong>File Handling/Group Folders</strong>
              </p>

              <Box sx={{ minWidth: 310, marginTop: 5 }}>
                <Slider
                  aria-label="Score"
                  value={formData.fileHandling}
                  defaultValue={0}
                  getAriaValueText={valuetext}
                  name="fileHandling"
                  onChange={changeValue}
                  valueLabelDisplay="on"
                  step={10}
                  marks={marksFileHandling}
                  min={0}
                  max={100}
                />
              </Box>

              <br></br>
              <p>
                <strong>Data/Slates</strong>
              </p>

              <Box sx={{ minWidth: 310, marginTop: 5 }}>
                <Slider
                  aria-label="Score"
                  value={formData.dataSlates}
                  defaultValue={0}
                  getAriaValueText={valuetext}
                  name="dataSlates"
                  onChange={changeValue}
                  valueLabelDisplay="on"
                  step={10}
                  marks={marksDataSlates}
                  min={0}
                  max={100}
                  disabled={controlDisabled ? true : false}
                />
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    name="dataSlatesIgnored"
                    onClick={handleCheckBoxClick}
                  />
                }
                label="Not applicable for this feedback (Score is ignored)"
              />

              <br></br>
              <p>
                <strong>Posing/Cropping</strong>
              </p>

              <Box sx={{ minWidth: 310, marginTop: 5 }}>
                <Slider
                  aria-label="Score"
                  value={formData.posingCropping}
                  defaultValue={0}
                  getAriaValueText={valuetext}
                  name="posingCropping"
                  onChange={changeValue}
                  valueLabelDisplay="on"
                  step={10}
                  marks={marksPosingCropping}
                  min={0}
                  max={100}
                />
              </Box>

              <br></br>
              <p>
                <strong>Background/Lighting</strong>
              </p>

              <Box sx={{ minWidth: 310, marginTop: 5 }}>
                <Slider
                  aria-label="Score"
                  value={formData.backgroundLighting}
                  defaultValue={0}
                  getAriaValueText={valuetext}
                  name="backgroundLighting"
                  onChange={changeValue}
                  valueLabelDisplay="on"
                  step={10}
                  marks={marksBackgroundLighting}
                  min={0}
                  max={100}
                />
              </Box>
              <br></br>
              <p>
                <strong>Glasses/Photoshop Work</strong>
              </p>

              <Box sx={{ minWidth: 310, marginTop: 5 }}>
                <Slider
                  aria-label="Score"
                  value={formData.glassesPhotoshop}
                  defaultValue={0}
                  getAriaValueText={valuetext}
                  name="glassesPhotoshop"
                  onChange={changeValue}
                  valueLabelDisplay="on"
                  step={10}
                  marks={marksGlassesPhotoshop}
                  min={0}
                  max={100}
                />
              </Box>
              <br></br>
              {isSuccessfullySubmitted && (
                <div className="success">
                  Thanks for leaving feedback for the team - submitted
                  successfully :)
                </div>
              )}
              {error && <Alert severity="error">{error}</Alert>}
              <br></br>
              <br></br>
              <button
                type="submit"
                class="btn btn-primary"
                onClick={handleSubmit}
                intent="primary"
              >
                Submit feedback
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterFeedback;
