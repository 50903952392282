import React from "react";
import Header from "./Header";

const PrivacyPolicy = () => {
  return (
    <div>
      <Header></Header>
      <div class="container-fluid body">
        <h1>PRIVACY POLICY</h1>

        <p>Last updated April 25, 2023</p>
        <p>&nbsp;</p>
        <p>
          This privacy notice for Colorfoto (&#39;Company&#39;, &#39;we&#39;,
          &#39;us&#39;, or &#39;our&#39;), describes how and why we might
          collect, store, use, and/or share (&#39;process&#39;) your information
          when you use our services (&#39;Services&#39;), such as when you:
        </p>
        <ul>
          <li>
            Visit our website at pg-portal.colorfoto.net, or any website of ours
            that links to this privacy notice
          </li>
          <li>
            Engage with us in other related ways, including any sales,
            marketing, or events
          </li>
        </ul>
        <p>
          Questions or concerns?&nbsp;Reading this privacy notice will help you
          understand your privacy rights and choices. If you do not agree with
          our policies and practices, please do not use our Services. If you
          still have any questions or concerns, please contact us at __________.
        </p>
        <p>&nbsp;</p>
        <p>SUMMARY OF KEY POINTS</p>
        <p>&nbsp;</p>
        <p>
          This summary provides key points from our privacy notice, but you can
          find out more details about any of these topics by clicking the link
          following each key point or by using our table of contents below to
          find the section you are looking for.
        </p>
        <p>&nbsp;</p>
        <p>
          What personal information do we process? When you visit, use, or
          navigate our Services, we may process personal information depending
          on how you interact with Colorfoto and the Services, the choices you
          make, and the products and features you use. Learn more about personal
          information you disclose to us
        </p>
        <p>&nbsp;</p>
        <p>
          Do we process any sensitive personal information? We do not process
          sensitive personal information.
        </p>
        <p>&nbsp;</p>
        <p>
          Do we receive any information from third parties? We do not receive
          any information from third parties.
        </p>
        <p>&nbsp;</p>
        <p>
          How do we process your information? We process your information to
          provide, improve, and administer our Services, communicate with you,
          for security and fraud prevention, and to comply with law. We may also
          process your information for other purposes with your consent. We
          process your information only when we have a valid legal reason to do
          so. Learn more abouthow we process your information.
        </p>
        <p>&nbsp;</p>
        <p>
          In what situations and with which parties do we share personal
          information? We may share information in specific situations and with
          specific third parties. Learn more about when and with whom we share
          your personal information .
        </p>
        <p>&nbsp;</p>
        <p>
          How do we keep your information safe? We have organisational and
          technical processes and procedures in place to protect your personal
          information. However, no electronic transmission over the internet or
          information storage technology can be guaranteed to be 100% secure, so
          we cannot promise or guarantee that hackers, cybercriminals, or other
          unauthorised third parties will not be able to defeat our security and
          improperly collect, access, steal, or modify your information. Learn
          more about how we keep your information safe.
        </p>
        <p>&nbsp;</p>
        <p>
          What are your rights? Depending on where you are located
          geographically, the applicable privacy law may mean you have certain
          rights regarding your personal information. Learn more about your
          privacy rights.
        </p>
        <p>&nbsp;</p>
        <p>
          How do you exercise your rights? The easiest way to exercise your
          rights is by submitting a data subject access request , or by
          contacting us. We will consider and act upon any request in accordance
          with applicable data protection laws.
        </p>
        <p>&nbsp;</p>
        <p>
          Want to learn more about what Colorfoto does with any information we
          collect? Review the privacy notice in full.
        </p>
        <p>&nbsp;</p>
        <p>TABLE OF CONTENTS</p>
        <p>&nbsp;</p>
        <p>1. WHAT INFORMATION DO WE COLLECT?</p>
        <p>2. HOW DO WE PROCESS YOUR INFORMATION?</p>
        <p>
          3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
          INFORMATION?
        </p>
        <p>
          4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION? &nbsp;
        </p>
        <p>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</p>
        <p>6. HOW LONG DO WE KEEP YOUR INFORMATION?</p>
        <p>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</p>
        <p>8. DO WE COLLECT INFORMATION FROM MINORS?</p>
        <p>9. WHAT ARE YOUR PRIVACY RIGHTS?</p>
        <p>10. CONTROLS FOR DO-NOT-TRACK FEATURES</p>
        <p>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</p>
        <p>12. DO WE MAKE UPDATES TO THIS NOTICE?</p>
        <p>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</p>
        <p>
          14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </p>
        <p>&nbsp;</p>
        <p>1. WHAT INFORMATION DO WE COLLECT?</p>
        <p>&nbsp;</p>
        <p>Personal information you disclose to us</p>
        <p>&nbsp;</p>
        <p>
          In Short:&nbsp;We collect personal information that you provide to us.
        </p>
        <p>&nbsp;</p>
        <p>
          We collect personal information that you voluntarily provide to us
          when you register on the Services,&nbsp;express an interest in
          obtaining information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us.
        </p>
        <p>&nbsp;</p>
        <p>
          Personal Information Provided by You. The personal information that we
          collect depends on the context of your interactions with us and the
          Services, the choices you make, and the products and features you use.
          The personal information we collect may include the following:
        </p>
        <ul>
          <li>names</li>
        </ul>
        <ul>
          <li>email addresses</li>
        </ul>
        <ul>
          <li>usernames</li>
        </ul>
        <ul>
          <li>passwords</li>
        </ul>
        <ul>
          <li>job titles</li>
        </ul>
        <p>&nbsp;</p>
        <p>Sensitive Information. We do not process sensitive information.</p>
        <p>&nbsp;</p>
        <p>
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </p>
        <p>&nbsp;</p>
        <p>Information automatically collected</p>
        <p>&nbsp;</p>
        <p>
          In Short:&nbsp;Some information &mdash; such as your Internet Protocol
          (IP) address and/or browser and device characteristics &mdash; is
          collected automatically when you visit our Services.
        </p>
        <p>&nbsp;</p>
        <p>
          We automatically collect certain information when you visit, use, or
          navigate the Services. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Services, and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes.
        </p>
        <p>&nbsp;</p>
        <p>
          Like many businesses, we also collect information through cookies and
          similar technologies.
        </p>
        <p>&nbsp;</p>
        <p>The information we collect includes:</p>
        <ul>
          <li>
            Log and Usage Data. Log and usage data is service-related,
            diagnostic, usage, and performance information our servers
            automatically collect when you access or use our Services and which
            we record in log files. Depending on how you interact with us, this
            log data may include your IP address, device information, browser
            type, and settings and information about your activity in the
            Services&nbsp;(such as the date/time stamps associated with your
            usage, pages and files viewed, searches, and other actions you take
            such as which features you use), device event information (such as
            system activity, error reports (sometimes called &#39;crash
            dumps&#39;), and hardware settings).
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>2. HOW DO WE PROCESS YOUR INFORMATION?</p>
        <p>&nbsp;</p>
        <p>
          In Short:&nbsp;We process your information to provide, improve, and
          administer our Services, communicate with you, for security and fraud
          prevention, and to comply with law. We may also process your
          information for other purposes with your consent.
        </p>
        <p>&nbsp;</p>
        <p>
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including:
        </p>
        <ul>
          <li>
            To facilitate account creation and authentication and otherwise
            manage user accounts.&nbsp;We may process your information so you
            can create and log in to your account, as well as keep your account
            in working order.
          </li>
        </ul>
        <ul>
          <li>
            To deliver and facilitate delivery of services to the user.&nbsp;We
            may process your information to provide you with the requested
            service.
          </li>
        </ul>
        <ul>
          <li>
            To send administrative information to you.&nbsp;We may process your
            information to send you details about our products and services,
            changes to our terms and policies, and other similar information.
          </li>
        </ul>
        <ul>
          <li>
            To enable user-to-user communications.&nbsp;We may process your
            information if you choose to use any of our offerings that allow for
            communication with another user.
          </li>
        </ul>
        <ul>
          <li>
            To identify usage trends. We may process information about how you
            use our Services to better understand how they are being used so we
            can improve them.
          </li>
        </ul>
        <ul>
          <li>
            To save or protect an individual&#39;s vital interest. We may
            process your information when necessary to save or protect an
            individual&rsquo;s vital interest, such as to prevent harm.
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</p>
        <p>&nbsp;</p>
        <p>
          In Short:&nbsp;We only process your personal information when we
          believe it is necessary and we have a valid legal reason (i.e. legal
          basis) to do so under applicable law, like with your consent, to
          comply with laws, to provide you with services to enter into or fulfil
          our contractual obligations, to protect your rights, or to fulfil our
          legitimate business interests.
        </p>
        <p>&nbsp;</p>
        <p>
          The General Data Protection Regulation (GDPR) and UK GDPR require us
          to explain the valid legal bases we rely on in order to process your
          personal information. As such, we may rely on the following legal
          bases to process your personal information:
        </p>
        <ul>
          <li>
            Consent.&nbsp;We may process your information if you have given us
            permission (i.e. consent) to use your personal information for a
            specific purpose. You can withdraw your consent at any time. Learn
            more about withdrawing your consent.
          </li>
        </ul>
        <ul>
          <li>
            Performance of a Contract. We may process your personal information
            when we believe it is necessary to fulfil our contractual
            obligations to you, including providing our Services or at your
            request prior to entering into a contract with you.
          </li>
        </ul>
        <ul>
          <li>
            Legitimate Interests. We may process your information when we
            believe it is reasonably necessary to achieve our legitimate
            business interests and those interests do not outweigh your
            interests and fundamental rights and freedoms. For example, we may
            process your personal information for some of the purposes described
            in order to:
          </li>
        </ul>
        <ul>
          <li>
            Analyse how our Services are used so we can improve them to engage
            and retain users
          </li>
        </ul>
        <ul>
          <li>
            Legal Obligations. We may process your information where we believe
            it is necessary for compliance with our legal obligations, such as
            to cooperate with a law enforcement body or regulatory agency,
            exercise or defend our legal rights, or disclose your information as
            evidence in litigation in which we are involved.
          </li>
        </ul>
        <ul>
          <li>
            Vital Interests. We may process your information where we believe it
            is necessary to protect your vital interests or the vital interests
            of a third party, such as situations involving potential threats to
            the safety of any person.
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</p>
        <p>&nbsp;</p>
        <p>
          In Short:&nbsp;We may share information in specific situations
          described in this section and/or with the following third parties.
        </p>
        <p>&nbsp;</p>
        <p>
          We may need to share your personal information in the following
          situations:
        </p>
        <ul>
          <li>
            Business Transfers. We may share or transfer your information in
            connection with, or during negotiations of, any merger, sale of
            company assets, financing, or acquisition of all or a portion of our
            business to another company.
          </li>
        </ul>
        <ul>
          <li>
            Business Partners. We may share your information with our business
            partners to offer you certain products, services, or promotions.
          </li>
        </ul>
        <ul>
          <li>
            Other Users. When you share personal information (for example, by
            posting comments, contributions, or other content to the Services)
            or otherwise interact with public areas of the Services, such
            personal information may be viewed by all users and may be publicly
            made available outside the Services in perpetuity. Similarly, other
            users will be able to view descriptions of your activity,
            communicate with you within our Services, and view your profile.
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</p>
        <p>&nbsp;</p>
        <p>
          In Short:&nbsp;We may use cookies and other tracking technologies to
          collect and store your information.
        </p>
        <p>&nbsp;</p>
        <p>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Notice.
        </p>
        <p>&nbsp;</p>
        <p>6. HOW LONG DO WE KEEP YOUR INFORMATION?</p>
        <p>&nbsp;</p>
        <p>
          In Short:&nbsp;We keep your information for as long as necessary to
          fulfil the purposes outlined in this privacy notice unless otherwise
          required by law.
        </p>
        <p>&nbsp;</p>
        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than the
          period of time in which users have an account with us.
        </p>
        <p>&nbsp;</p>
        <p>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymise such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>
        <p>&nbsp;</p>
        <p>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</p>
        <p>&nbsp;</p>
        <p>
          In Short:&nbsp;We aim to protect your personal information through a
          system of organisational and technical security measures.
        </p>
        <p>&nbsp;</p>
        <p>
          We have implemented appropriate and reasonable technical and
          organisational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorised third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </p>
        <p>&nbsp;</p>
        <p>8. DO WE COLLECT INFORMATION FROM MINORS?</p>
        <p>&nbsp;</p>
        <p>
          In Short:&nbsp;We do not knowingly collect data from or market to
          children under 18 years of age.
        </p>
        <p>&nbsp;</p>
        <p>
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the Services, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent&rsquo;s use of the Services. If we
          learn that personal information from users less than 18 years of age
          has been collected, we will deactivate the account and take reasonable
          measures to promptly delete such data from our records. If you become
          aware of any data we may have collected from children under age 18,
          please contact us at accounts@daviescolour.co.uk.
        </p>
        <p>&nbsp;</p>
        <p>9. WHAT ARE YOUR PRIVACY RIGHTS?</p>
        <p>&nbsp;</p>
        <p>
          In Short:&nbsp;In some regions, such as the European Economic Area
          (EEA) and United Kingdom (UK), you have rights that allow you greater
          access to and control over your personal information.&nbsp;You may
          review, change, or terminate your account at any time.
        </p>
        <p>&nbsp;</p>
        <p>
          In some regions (like the EEA and UK), you have certain rights under
          applicable data protection laws. These may include the right (i) to
          request access and obtain a copy of your personal information, (ii) to
          request rectification or erasure; (iii) to restrict the processing of
          your personal information; and (iv) if applicable, to data
          portability. In certain circumstances, you may also have the right to
          object to the processing of your personal information. You can make
          such a request by contacting us by using the contact details provided
          in the section HOW CAN YOU CONTACT US ABOUT THIS NOTICE?&#39; below.
        </p>
        <p>&nbsp;</p>
        <p>
          We will consider and act upon any request in accordance with
          applicable data protection laws.
        </p>
        <p>&nbsp;</p>
        <p>
          If you are located in the EEA or UK and you believe we are unlawfully
          processing your personal information, you also have the right to
          complain to your Member State data protection authority or&nbsp; UK
          data protection authority .
        </p>
        <p>&nbsp;</p>
        <p>
          If you are located in Switzerland, you may contact the Federal Data
          Protection and Information Commissioner .
        </p>
        <p>&nbsp;</p>
        <p>
          Withdrawing your consent: If we are relying on your consent to process
          your personal information, you have the right to withdraw your consent
          at any time. You can withdraw your consent at any time by contacting
          us by using the contact details provided in the section &#39; HOW CAN
          YOU CONTACT US ABOUT THIS NOTICE?&#39; below.
        </p>
        <p>&nbsp;</p>
        <p>
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, will it affect the processing of
          your personal information conducted in reliance on lawful processing
          grounds other than consent.
        </p>
        <p>&nbsp;</p>
        <p>Account Information</p>
        <p>&nbsp;</p>
        <p>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </p>
        <ul>
          <li>Contact us using the contact information provided.</li>
        </ul>
        <p>&nbsp;</p>
        <p>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </p>
        <p>&nbsp;</p>
        <p>
          Cookies and similar technologies: Most Web browsers are set to accept
          cookies by default. If you prefer, you can usually choose to set your
          browser to remove cookies and to reject cookies. If you choose to
          remove cookies or reject cookies, this could affect certain features
          or services of our Services. You may also opt out of interest-based
          advertising by advertisers on our Services.
        </p>
        <p>&nbsp;</p>
        <p>
          If you have questions or comments about your privacy rights, you may
          email us at colorfotopgportal@gmail.com.
        </p>
        <p>&nbsp;</p>
        <p>10. CONTROLS FOR DO-NOT-TRACK FEATURES</p>
        <p>&nbsp;</p>
        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track (&#39;DNT&#39;) feature or setting
          you can activate to signal your privacy preference not to have data
          about your online browsing activities monitored and collected. At this
          stage no uniform technology standard for recognising and implementing
          DNT signals has been finalised. As such, we do not currently respond
          to DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </p>
        <p>&nbsp;</p>
        <p>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</p>
        <p>&nbsp;</p>
        <p>
          In Short:&nbsp;Yes, if you are a resident of California, you are
          granted specific rights regarding access to your personal information.
        </p>
        <p>&nbsp;</p>
        <p>
          California Civil Code Section 1798.83, also known as the &#39;Shine
          The Light&#39; law, permits our users who are California residents to
          request and obtain from us, once a year and free of charge,
          information about categories of personal information (if any) we
          disclosed to third parties for direct marketing purposes and the names
          and addresses of all third parties with which we shared personal
          information in the immediately preceding calendar year. If you are a
          California resident and would like to make such a request, please
          submit your request in writing to us using the contact information
          provided below.
        </p>
        <p>&nbsp;</p>
        <p>
          If you are under 18 years of age, reside in California, and have a
          registered account with Services, you have the right to request
          removal of unwanted data that you publicly post on the Services. To
          request removal of such data, please contact us using the contact
          information provided below and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Services, but
          please be aware that the data may not be completely or comprehensively
          removed from all our systems (e.g. backups, etc.).
        </p>
        <p>&nbsp;</p>
        <p>12. DO WE MAKE UPDATES TO THIS NOTICE?</p>
        <p>&nbsp;</p>
        <p>
          In Short:&nbsp;Yes, we will update this notice as necessary to stay
          compliant with relevant laws.
        </p>
        <p>&nbsp;</p>
        <p>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated &#39;Revised&#39; date and the
          updated version will be effective as soon as it is accessible. If we
          make material changes to this privacy notice, we may notify you either
          by prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </p>
        <p>&nbsp;</p>
        <p>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</p>
        <p>&nbsp;</p>
        <p>
          If you have questions or comments about this notice, you may contact
          our Data Protection Officer (DPO) , Richard Tinkler,&nbsp;by email
          at&nbsp;accounts@daviescolour.co.uk,&nbsp;by phone at
          02920448210,&nbsp;or by post to:
        </p>
        <p>&nbsp;</p>
        <p>Colorfoto</p>
        <p>Richard Tinkler</p>
        <p>Image House</p>
        <p>Jubilee Trading Estate</p>
        <p>Cardiff CF24 5EF</p>
        <p>United Kingdom</p>
        <p>&nbsp;</p>
        <p>
          14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </p>
        <p>&nbsp;</p>
        <p>
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          change that information, or delete it. To request to review, update,
          or delete your personal information, please fill out and submit
          a&nbsp; data subject access request .
        </p>
        <p>&nbsp;</p>
        <p>This cookie policy was created using Termly&#39;</p>
        <p>&nbsp;</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
