import Card from "@mui/material/Card";
import { CardContent } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import warningIcon from "../images/warningIcon.png";
import proIcon from "../images/pro.png";
import Header from "../components/Header";

const TransferWorkEnterInfo = () => {
  return (
    <div>
      <Header></Header>
      <div class="container-fluid body">
        <div class="row">
          <div class="col-sm">
            <h1>Transfer work</h1>

            <Card>
              <CardContent>
                <img src={warningIcon} alt="warning_icon" />
                <p>
                  When you transfer your work to the lab, you{" "}
                  <strong>MUST</strong> ensure that you include:
                </p>
                <ul>
                  <li>
                    A photo of the <strong>FRONT and BACK</strong> of your
                    paperwork
                  </li>
                  <li>
                    Your <strong>UNEDITED and EDITED</strong> images
                  </li>
                  <li>
                    A photo of any <strong>staff sheets</strong>
                  </li>
                </ul>
                <p>
                  You can then hand in the physical paperwork to Sara when you
                  are next in the lab.
                </p>
                <h6>
                  <strong>
                    If you fail to do this, the uploader will be blocked for
                    your user account.
                  </strong>
                </h6>
              </CardContent>
            </Card>
          </div>

          <div class="col-sm">
            <Card className="marginTop75">
              <CardContent>
                <p>
                  <strong>
                    Click "Send your work to the lab" to get started
                  </strong>
                </p>
                <br></br>
                <p>Filemail Pro</p>
                <ul>
                  <li>Over 4x faster than Filemail Free</li>
                  <li>No 24 hour transfer limits per device</li>
                  <li>Email address of the lab is filled out for you</li>
                  <li>No Filemail account needed</li>
                  <br></br>
                </ul>

                <img src={proIcon} alt="pro_icon" />
                <NavLink to="/transfer">
                  {" "}
                  <button
                    type="button"
                    className="btn btn-primary m-2"
                    minimal
                    intent="primary"
                  >
                    Send your work to the lab
                  </button>
                </NavLink>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferWorkEnterInfo;
