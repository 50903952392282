import Card from "@mui/material/Card";
import { CardContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTable, useFilters, usePagination, useSortBy } from "react-table";
import { Accordion } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { PageButton } from "../components/Button";
import Moment from "moment";
import Header from "../components/Header";
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone";
import { blueGrey, purple } from "@mui/material/colors";
import { Table, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

// Create the parameters for the bucket

const StudentDataFiles = (props) => {
  // 1) First, create a const and set it to use state, declaring a setData setter method
  const [data, setData] = useState([]);

  // 2) "Hook" into React's useEffect functionality so that this method is run on page render
  useEffect(() => {
    fetchData();
  }, []);

  // 3) Method that is called when page renders, axios request to middleware and then use setter
  // method to set the data into the const we created earlier, so we can map it into a table
  const fetchData = () => {
    const url = process.env.REACT_APP_API_ENDPOINT + "aws/data-file-items";

    axios({
      url: url,
      method: "GET",
    })
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const downloadTestFile = (e) => {
    // const fileName = "TestDataForAws2.csv"
    // e.preventDefault();
    // //alert(process.env.REACT_APP_API_ENDPOINT + 'aws/aws-downloadTestDataForAws999.csv');
    // const url = process.env.REACT_APP_API_ENDPOINT + 'aws/aws-download' + fileName
    // axios({
    //     url: url,
    //     method: 'GET',
    //     responseType: 'blob', // very very important
    // }).then((response) => {
    //     const url = window.URL.createObjectURL(new Blob([response.data]));
    //     console.log(response.data)
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', fileName);
    //     document.body.appendChild(link);
    //     link.click();
    // });
  };

  const downloadFileTest = (e) => {
    // const fileName = "TestDataForAws2.csv"
    // e.preventDefault();
    // //alert(process.env.REACT_APP_API_ENDPOINT + 'aws/aws-downloadTestDataForAws999.csv');
    // const url = process.env.REACT_APP_API_ENDPOINT + 'aws/aws-download-datafile' + fileName
    // axios({
    //     url: url,
    //     method: 'GET',
    //     responseType: 'blob', // very very important
    // }).then((response) => {
    //     const url = window.URL.createObjectURL(new Blob([response.data]));
    //     console.log(response.data)
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', fileName);
    //     document.body.appendChild(link);
    //     link.click();
    // });
  };

  function downloadDataFile(passedData) {
    const fileName = passedData;

    //alert(process.env.REACT_APP_API_ENDPOINT + 'aws/aws-downloadTestDataForAws999.csv');

    const url =
      process.env.REACT_APP_API_ENDPOINT + "aws/download-datafile" + fileName;

    axios({
      url: url,
      method: "GET",
      responseType: "blob", // very very important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      //  console.log(response.data)
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    });
  }

  function deleteDataFile(passedData) {
    const fileName = passedData;

    //alert(process.env.REACT_APP_API_ENDPOINT + 'aws/aws-downloadTestDataForAws999.csv');

    const url =
      process.env.REACT_APP_API_ENDPOINT + "aws/delete-datafile" + fileName;

    axios({
      url: url,
      method: "DELETE",
    })
      .then((response) => {
        // console.log(response.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Student Data Files ",
        columns: [
          {
            Header: "Filename",
            accessor: "Key",
          },
          {
            Header: "Date modified",
            accessor: (d) => {
              return Moment(d.LastModified).local().format("DD-MM-YYYY");
            },
          },
        ],
      },
    ],
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: TextFilter,
    }),
    []
  );

  const sortees = React.useMemo(
    () => [
      {
        id: "Key",
        desc: false,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageOptions,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy: sortees,
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  function TextFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  }

  return (
    <div>
      <Header></Header>
      <div className="container-fluid body">
        <div className="row">
          <div className="col">
            <Card className="mt-3">
              <CardContent>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <ErrorTwoToneIcon
                        sx={{ color: purple[800], fontSize: 38 }}
                      ></ErrorTwoToneIcon>{" "}
                      Download Instructions...
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <ul>
                        <li>
                          In the table below you can browse for and download the{" "}
                          <strong>student data files in CSV format</strong> for
                          Control My Nikon{" "}
                        </li>
                        <br></br>
                        <li>
                          You can search by filename or date modified in the
                          table below to filter the table down{" "}
                        </li>
                        <br></br>
                        <li>
                          Click on any file name to download the file to your
                          device (please wait 5-10 seconds for the file to be
                          retrieved)
                        </li>
                        <br></br>
                      </ul>
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <p className="marginLeft70"></p>
                <p className="marginLeft70"></p>
              </CardContent>
            </Card>

            <div className="mt-2 flex flex-col">
              <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-gray-100">
                    <Table
                      {...getTableProps()}
                      className="specialCursor min-w-full divide-y divide-gray-200"
                    >
                      <thead className="bg-gray-50">
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th
                                className="px-6 py-3 text-left text-medium font-medium text-gray-500 uppercase tracking-wider"
                                {...column.getHeaderProps()}
                              >
                                {column.render("Header")}
                                <div>
                                  {column.canFilter
                                    ? column.render("Filter")
                                    : null}
                                </div>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <Tbody
                        className="bg-white divide-y divide-gray-200"
                        {...getTableBodyProps()}
                      >
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <Tr
                              {...row.getRowProps()}
                              onClick={() => downloadDataFile(row.original.Key)}
                            >
                              {row.cells.map((cell) => {
                                return (
                                  <td
                                    className="px-6 py-3 whitespace-nowrap"
                                    {...cell.getCellProps()}
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                    <div className="px-6 py-3 bg-gray-100 text-left text-medium font-medium text-gray-500 uppercase tracking-wider">
                      Page{" "}
                      <em>
                        {pageIndex + 1} of {pageOptions.length}
                      </em>
                      <div className="floatRightWithMargin">
                        <PageButton
                          className="rounded-l-md"
                          onClick={() => previousPage()}
                          disabled={!canPreviousPage}
                        >
                          <ChevronLeftIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                          Previous
                        </PageButton>

                        <PageButton
                          className="rounded-l-md"
                          onClick={() => nextPage()}
                          disabled={!canNextPage}
                        >
                          <ChevronRightIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                          Next
                        </PageButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDataFiles;
