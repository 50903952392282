import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  ChangeEvent,
} from "react";
import Header from "./Header";
import { Accordion, CardContent } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Card from "@mui/material/Card";
import spreadsheetIcon from "../images/spreadsheet.png";
import axios from "axios";
import { UserContext } from "../context/UserContext";
import FileUploadMultiple from "./UploadFiles";
import fileDownload from "js-file-download";
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone";
import { purple } from "@mui/material/colors";
import Alert from "@mui/material/Alert";

const Timesheets = (props) => {
  const [data, setData] = useState([]);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const initialFormData = Object.freeze({
    recipientName: "",
    recipientEmail: "accounts@colorfoto.net",
    senderName: "",
    subject: "",
    message: "",
  });
  const recipientEmail = "accounts@colorfoto.net";
  const [formData, updateFormData] = React.useState(initialFormData);
  const [selectedRecipients, updateSelectedRecipients] = React.useState("");
  const [userContext, setUserContext] = useContext(UserContext);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);

  useEffect(() => {
    if (!userContext.details) {
      fetchUserDetails();
    } else {
      //fetchPhotographerNames();
    }
  }, []);

  const handleChange = (e) => {
    //console.log(e.target.value)
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]: e.target.value,
    });

    //console.log(formData);
  };

  // const handleFileSelect = (event) => {
  //   setSelectedFile(event.target.files[0]);
  // };

  const handleClick = (e) => {
    const url = process.env.REACT_APP_API_ENDPOINT + "download";
    axios({
      url: url,
      method: "GET",
      responseType: "blob",
    })
      .then((res) => {
        // fileDownload(res.data, "template.xlsx");
        const href = URL.createObjectURL(res.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "timesheet_template.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const fetchUserDetails = useCallback(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "users/me", {
      method: "GET",
      credentials: "include",
      //Pass authentication token as bearer token in header
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userContext.token}`,
      },
    }).then(async (response) => {
      if (response.ok) {
        const data = await response.json();
        setUserContext((oldValues) => {
          return { ...oldValues, details: data };
        });
      } else {
        if (response.status === 401) {
          // Edge case: when the token has expired.
          //This could happen if the refreshToken calls have failed due to network error or
          // User has had the tab open from previous day and tries to click on the Fetch button
          //window.location.reload()
        } else {
          setUserContext((oldValues) => {
            return { ...oldValues, details: null };
          });
        }
      }
    });
  }, [setUserContext, userContext.token]);

  // Multiple file upload code =-=-=-=-=--=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  const [files, setFiles] = useState("");
  //state for checking file size
  const [fileSize, setFileSize] = useState(true);
  // for file upload progress message
  const [fileUploadProgress, setFileUploadProgress] = useState(false);
  //for displaying response message
  const [fileUploadResponse, setFileUploadResponse] = useState(null);
  //base end point url
  //const FILE_UPLOAD_BASE_ENDPOINT = "http://localhost:8282";

  const uploadFileHandler = (event) => {
    setFiles(event.target.files);
  };

  const fileSubmitHandler = (event) => {
    event.preventDefault();
    setFileSize(true);
    setFileUploadProgress(true);
    setFileUploadResponse(null);

    const formData = new FormData();

    let i = 0;

    for (i = 0; i <= files.length; i++) {
      formData.append("arrayOfFilesName", files[i]);
    }

    // append who has sent the request
    formData.append(
      "senderName",
      JSON.stringify(
        userContext.details.firstName + " " + userContext.details.lastName
      )
    );

    // append the email it is being sent to
    formData.append("recipientEmail", JSON.stringify(recipientEmail));

    // formData.append("arrayOfFilesName", files[0]);
    // formData.append("arrayOfFilesName", files[1]);

    //console.log(formData);

    axios({
      method: "POST",
      url: process.env.REACT_APP_API_ENDPOINT + "send-with-attachments",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    setFileUploadProgress(false);
    setIsSuccessfullySubmitted(true);
    // const requestOptions = {
    //   method: "POST",
    //   body: formData,
    // };
    // fetch(C1342G0J
    //   process.env.REACT_APP_API_ENDPOINT + "send-with-attachments",
    //   requestOptions
    // )
    //   .then(async (response) => {
    //     const isJson = response.headers
    //       .get("content-type")
    //       ?.includes("application/json");
    //     const data = isJson && (await response.json());

    //     // check for error response
    //     if (!response.ok) {
    //       // get error message
    //       const error = (data && data.message) || response.status;
    //       setFileUploadResponse(data.message);
    //       return Promise.reject(error);
    //     }

    //     console.log(data.message);
    //     setFileUploadResponse(data.message);
    //   })
    //   .catch((error) => {
    //     console.error("Error while uploading file!", error);
    //   });
    // setFileUploadProgress(false);
  };

  const { history } = props;
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleWriteMessageListItemClick = () => {
    history.push({
      pathname: "/compose",
    });
  };
  const handleInboxListItemClick = () => {
    history.push({
      pathname: "/inbox",
    });
  };

  const handleSentListItemClick = () => {
    history.push({
      pathname: "/sent-items",
    });
  };

  // multiple file uploads code -=-=-=-=---=--=

  return (
    <div>
      <Header></Header>
      <div class="container-fluid body">
        <div class="row">
          <div class="col-sm">
            <Card className="mt-3">
              <CardContent>
                <h6>Instructions</h6>
                <br></br>
                <p>
                  <strong>EITHER:</strong>
                </p>
                <p>
                  Upload a photo of your filled in timesheets with any
                  photographs of expense receipts using the form on the right
                </p>
                <p>
                  <strong>OR:</strong>
                </p>
                <p>
                  Download the timesheet template below to save a copy to your
                  device.{" "}
                </p>
                <p>
                  {" "}
                  Fill it in digitally, and attach the timesheet and photographs
                  of any expense receipts using the form to the right
                </p>
                <br></br>
              </CardContent>
            </Card>
            <Card className="mt-3">
              <CardContent>
                <img
                  src={spreadsheetIcon}
                  alt="spreadsheet"
                  className="homeIcons"
                ></img>
                Timesheet template
                <br></br>
                <br></br>
                <button
                  type="submit"
                  class="btn btn-primary"
                  intent="primary"
                  onClick={handleClick}
                >
                  Download template
                </button>
                <br></br>
                <br></br>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <ErrorTwoToneIcon
                        sx={{ color: purple[800], fontSize: 38 }}
                      ></ErrorTwoToneIcon>{" "}
                      Need help opening the Excel file?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <p>First time setup</p>
                      <hr></hr>
                      <p>
                        You only need to do this once. You won't have Excel on
                        your laptop, but every laptop should have "Libre Office
                        Calc" downloaded, which is free to use
                      </p>
                      <p>
                        <strong>
                          You'll need to open the downloaded spreadsheet in
                          Libre Office NOT Excel by right clicking it and
                          selecting Open With - Choose another app - LibreOffice
                          Calc and tick the setting "Always open..."
                        </strong>
                      </p>
                      <p>
                        Please message Adam on the portal if you need help with
                        this and the setting will be changed for you
                      </p>
                      <hr></hr>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <br></br>
                <br></br>
              </CardContent>
            </Card>
          </div>
          <div class="col-sm">
            <Card className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-gray-50 mt-3">
              <CardContent>
                <div className="text-left text-medium font-medium text-gray-500 uppercase tracking-wider">
                  <h6>Send Timesheets</h6>
                </div>

                <form>
                  <div class="form-group"></div>
                  <br></br>
                  <div class="form-group">
                    <label for="exampleInputEmail1">To</label>
                    <input
                      type="text"
                      className="form-control"
                      name="senderName"
                      id="exampleInputEmail1"
                      readOnly
                      aria-describedby="emailHelp"
                      value="accounts@colorfoto.net"
                      onChange={handleChange}
                    />
                  </div>
                  <br></br>
                  <div class="form-group">
                    <label for="exampleInputEmail1">From</label>
                    <input
                      type="text"
                      className="form-control"
                      name="senderName"
                      id="exampleInputEmail1"
                      readOnly
                      aria-describedby="emailHelp"
                      value={
                        userContext.details.firstName +
                        " " +
                        userContext.details.lastName
                      }
                      onChange={handleChange}
                    />
                  </div>
                  <br></br>

                  <form>
                    <p>
                      <strong>
                        You have to attach your timesheets and ALL of your
                        receipts at the same time when you pick <br></br>{" "}
                        "Choose files...", so arrange your timesheets and
                        receipts all in one folder/place first
                      </strong>
                    </p>
                    <input type="file" multiple onChange={uploadFileHandler} />

                    {!fileSize && (
                      <p style={{ color: "red" }}>File size exceeded!!</p>
                    )}
                    {fileUploadProgress && (
                      <p style={{ color: "red" }}>Uploading File(s)</p>
                    )}
                    {fileUploadResponse != null && (
                      <p style={{ color: "green" }}>{fileUploadResponse}</p>
                    )}
                    <br></br>
                    <br></br>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      intent="primary"
                      onClick={fileSubmitHandler}
                    >
                      Submit
                    </button>
                  </form>
                  <br></br>
                  <br></br>
                  {isSuccessfullySubmitted && (
                    <Alert severity="success">
                      Timesheets and attachments sent succesfully
                    </Alert>
                  )}
                </form>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timesheets;
