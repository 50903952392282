import React, { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import { useTable, useFilters, usePagination, useSortBy } from "react-table";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import axios from "axios";
import Rating, { IconContainerProps } from "@mui/material/Rating";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import ratingOne from "../images/ratingOne.png";
import ratingTwo from "../images/ratingTwo.png";
import ratingThree from "../images/ratingThree.png";
import ratingFour from "../images/ratingFour.png";
import ratingFive from "../images/ratingFive.png";
import { flushSync } from "react-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { PageButton } from "../components/Button";
import Moment from "moment";
import Header from "../components/Header";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import MailIcon from "@mui/icons-material/Mail";
import { red } from "@mui/material/colors";
import { Table, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

const ViewFeedback = () => {
  // 1) First, create a const and set it to use state, declaring a setData setter method
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const history = useHistory();
  const [userContext, setUserContext] = useContext(UserContext);

  const fetchUserDetails = useCallback(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "users/me", {
      method: "GET",
      credentials: "include",
      //Pass authentication token as bearer token in header
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userContext.token}`,
      },
    }).then(async (response) => {
      if (response.ok) {
        const data = await response.json();
        flushSync(() => {
          setUserContext((oldValues) => {
            return { ...oldValues, details: data };
          });
        });
      } else {
        if (response.status === 401) {
          // Edge case: when the token has expired.
          //This could happen if the refreshToken calls have failed due to network error or
          // User has had the tab open from previous day and tries to click on the Fetch button
          //window.location.reload()
        } else {
          setUserContext((oldValues) => {
            return { ...oldValues, details: null };
          });
        }
      }
    });
  }, [setUserContext, userContext.token]);

  useEffect(() => {
    // fetch only when user details are not present
    if (!userContext.details) {
      fetchUserDetails();
      //console.log(userContext.firstName)
    } else {
      fetchFeedback();
    }
  }, [userContext.details, fetchUserDetails]);

  const refetchHandler = () => {
    //  set details to undefined so that spinner will be displayed and
    // fetchUserDetails will be invoked from useEffect
    setUserContext((oldValues) => {
      return { ...oldValues, details: undefined };
    });
  };

  // 2) Fetch the feedback and once the data has been set, call use effect
  const fetchFeedback = async () => {
    switch (userContext.details.team) {
      case "Photographers":
        try {
          const url =
            process.env.REACT_APP_API_ENDPOINT + "feedback/record/view";
          // console.log(userContext.details.firstName + " " + userContext.details.lastName)
          const response = await axios({
            method: "GET",
            url: url,
            params: {
              photographerName:
                userContext.details.firstName +
                " " +
                userContext.details.lastName,
            },
          });

          //const data = await response.json();
          //  console.log(response.data)
          setData(response.data);
        } catch (err) {
          console.log(err);
        }

        break;

      case "Lab":
        // console.log(userContext.details.firstName + " " + userContext.details.lastName)
        try {
          const url =
            process.env.REACT_APP_API_ENDPOINT + "feedback/record/viewAll";
          // console.log(userContext.details.firstName + " " + userContext.details.lastName)
          const response = await axios({
            method: "GET",
            url: url,
          });

          // const data = await response.json();
          //  console.log(response.data)
          setData(response.data);
        } catch (err) {
          console.log(err);
        }
        break;

      case "Head Office":
        try {
          const url =
            process.env.REACT_APP_API_ENDPOINT + "feedback/record/viewAll";
          // console.log(userContext.details.firstName + " " + userContext.details.lastName)
          const response = await axios({
            method: "GET",
            url: url,
          });

          // const data = await response.json();
          //  console.log(response.data)
          setData(response.data);
        } catch (err) {
          console.log(err);
        }

        break;
      default:
        // console.log(userContext.details.firstName + " " + userContext.details.lastName)
        try {
          const url =
            process.env.REACT_APP_API_ENDPOINT + "feedback/record/view";
          // console.log(userContext.details.firstName + " " + userContext.details.lastName)
          const response = await axios({
            method: "GET",
            url: url,
            params: {
              photographerName:
                userContext.details.firstName +
                " " +
                userContext.details.lastName,
            },
          });

          // const data = await response.json();
          //  console.log(response.data)
          setData(response.data);
        } catch (err) {
          console.log(err);
        }
        break;
    }
  };

  // 3) Call use effect, to update the rating value state this time, and call use effect again after that

  // 4) Now that the rating value has had time to set, we can FINALLY use the next method to do something with the rating value
  // function Welcome(props) {
  //     return <h1>Hello, {props.name}</h1>;
  // }

  // const logChanges = () => {
  //     //console.log(data.map(({ overallJobRating }) => overallJobRating))
  //     selectFeedbackFace();
  // };

  const StyledRating = styled(Rating)(({ theme }) => ({
    "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
      color: theme.palette.action.disabled,
    },
  }));

  const handleChange = (e) => {
    setData({
      ...data,

      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };

  const customIcons: {
    [index: string]: {
      icon: React.ReactElement,
      label: string,
    },
  } = {
    1: {
      icon: (
        <SentimentVeryDissatisfiedIcon
          style={{ width: "50px", height: "50px" }}
          color="error"
        />
      ),
      label: "Very Dissatisfied",
    },
    2: {
      icon: (
        <SentimentDissatisfiedIcon
          style={{ width: "50px", height: "50px" }}
          color="error"
        />
      ),
      label: "Dissatisfied",
    },
    3: {
      icon: (
        <SentimentSatisfiedIcon
          style={{ width: "50px", height: "50px" }}
          color="warning"
        />
      ),
      label: "Neutral",
    },
    4: {
      icon: (
        <SentimentSatisfiedAltIcon
          style={{ width: "50px", height: "50px" }}
          color="success"
        />
      ),
      label: "Satisfied",
    },
    5: {
      icon: (
        <SentimentVerySatisfiedIcon
          style={{ width: "50px", height: "50px" }}
          color="success"
        />
      ),
      label: "Very Satisfied",
    },
  };

  function IconContainer(props: IconContainerProps) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }

  const selectRows = (row) => {
    setSelectedRows({ selectedRows: row.original });
  };

  useEffect(() => {
    //console.log("Reached Use effect")
    if (isFirstRender === false) {
      redirectToPage();
    }

    if (isFirstRender === true) {
      setIsFirstRender(false);
    }
  }, [selectedRows]);

  const redirectToPage = () => {
    history.push({
      pathname: "/view-feedback-entry",
      userProps: {
        // location state
        selectedRows,
      },
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Review Feedback",
        columns: [
          {
            Header: "Shoot Date",
            accessor: (d) => {
              return Moment(d.dateOfFeedback).local().format("DD-MM-YYYY");
            },
            sortBy: "datetime",
          },
          {
            Header: "School Name",
            accessor: "schoolName",
          },
          {
            Header: "Photographer",
            accessor: "photographerName",
          },
          {
            Header: "Editor",
            accessor: "editorName",
          },
          {
            Header: "Job Type",
            accessor: "jobType",
          },
          {
            Header: "Overall Job Rating",
            accessor: "overallJobRating",
            Cell: ({ value }) => selectFeedbackFace(value),
          },
          {
            id: "feedbackRead",
            Header: "Status",
            accessor: (d) => d.feedbackRead.toString(),
            Cell: ({ value }) => selectUnreadReadIcon(value),
          },
        ],
      },
    ],
    []
  );

  const selectFeedbackFace = (value) => {
    switch (value) {
      case 1:
        return (
          <div key={1}>
            {[<img src={ratingOne} alt="unhappy_face" key={15} />]} 1/5
          </div>
        );
      case 2:
        return (
          <div key={2}>
            {[<img src={ratingTwo} alt="discontented_face" key={25} />]} 2/5
          </div>
        );
      case 3:
        return (
          <div key={3}>
            {[<img src={ratingThree} alt="neutral_face" key={35} />]} 3/5
          </div>
        );
      case 4:
        return (
          <div key={4}>
            {[<img src={ratingFour} alt="happy_face" key={45} />]} 4/5
          </div>
        );
      case 5:
        return (
          <div key={5}>
            {[<img src={ratingFive} alt="ecstatic_face" key={55} />]} 5/5
          </div>
        );
      default:
        return null;
    }

    // then return read/unread icon
  };

  const selectUnreadReadIcon = (value) => {
    switch (value) {
      case "true":
        return (
          <div key={1}>
            {[<MarkEmailReadIcon color="success" key={11} />]} Read
          </div>
        );
      case "false":
        return (
          <div key={2}>
            {[<MailIcon sx={{ color: red[900] }} key={22} />]} Unread
          </div>
        );
      default:
        return null;
    }

    // then return read/unread icon
  };

  const defaultColumn = React.useMemo(
    () => ({
      Filter: TextFilter,
    }),
    []
  );

  const sortees = React.useMemo(
    () => [
      {
        id: "shootDate",
        desc: false,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageOptions,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy: sortees,
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  function TextFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  }

  return userContext.details === null ? (
    "Error Loading User details"
  ) : userContext.details ? (
    // <Loader />
    <div>
      <Header></Header>
      <div className="container-fluid body">
        <div class="row">
          <div className="col">
            <div className="mt-2 flex flex-col">
              <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-gray-100">
                    <Table
                      {...getTableProps()}
                      className="specialCursor min-w-full divide-y divide-gray-200 highlight"
                    >
                      <thead className="bg-gray-50">
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th
                                className="px-6 py-3 text-left text-medium font-medium text-gray-500 uppercase tracking-wider"
                                {...column.getHeaderProps()}
                              >
                                {column.render("Header")}
                                <div>
                                  {column.canFilter
                                    ? column.render("Filter")
                                    : null}
                                </div>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <Tbody
                        className="bg-white divide-y divide-gray-200"
                        {...getTableBodyProps()}
                      >
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <Tr
                              {...row.getRowProps()}
                              onClick={() => selectRows(row)}
                            >
                              {row.cells.map((cell) => {
                                return (
                                  <td {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                    <div className="px-6 py-3 bg-gray-100 text-left text-medium font-medium text-gray-500 uppercase tracking-wider">
                      Page{" "}
                      <em>
                        {pageIndex + 1} of {pageOptions.length}
                      </em>
                      <div className="floatRightWithMargin">
                        <PageButton
                          className="rounded-l-md"
                          onClick={() => previousPage()}
                          disabled={!canPreviousPage}
                        >
                          <ChevronLeftIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                          Previous
                        </PageButton>

                        <PageButton
                          className="rounded-l-md"
                          onClick={() => nextPage()}
                          disabled={!canNextPage}
                        >
                          <ChevronRightIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                          Next
                        </PageButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default ViewFeedback;
