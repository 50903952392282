import Card from "@mui/material/Card";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import Loader from "./Loader";
import Avatar from "react-avatar";
import axios from "axios";
import { Accordion, CardContent } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Header from "../components/Header";
import { NavLink, useHistory } from "react-router-dom";
import { faComments, faMessage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ratingOne from "../images/ratingOne.png";
import ratingTwo from "../images/ratingTwo.png";
import ratingThree from "../images/ratingThree.png";
import ratingFour from "../images/ratingFour.png";
import ratingFive from "../images/ratingFive.png";
import Alert from "@mui/material/Alert";
import { blueGrey, purple } from "@mui/material/colors";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Footer from "../components/Footer";
import SchoolTwoToneIcon from "@mui/icons-material/SchoolTwoTone";
import UpdateTwoToneIcon from "@mui/icons-material/UpdateTwoTone";
import AnnouncementTwoToneIcon from "@mui/icons-material/AnnouncementTwoTone";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import ForumTwoToneIcon from "@mui/icons-material/ForumTwoTone";

const Home = (props) => {
  const [userContext, setUserContext] = useContext(UserContext);
  const [tasksData, setTasksData] = useState("");
  const [feedbackAverages, setFeedbackAverages] = useState("");
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [ratingFiveCount, setRatingFiveCount] = useState(0);
  const [ratingFourCount, setRatingFourCount] = useState(0);
  const [ratingThreeCount, setRatingThreeCount] = useState(0);
  const [ratingTwoCount, setRatingTwoCount] = useState(0);
  const [ratingOneCount, setRatingOneCount] = useState(0);
  const [numberFeedbackUnread, setNumberFeedbackUnread] = useState(0);

  // used for routing
  const history = useHistory();

  // control multiple calls with these vars
  const [updateFeedbackTriggered, setUpdateFeedbackTriggered] = useState(false);
  const [fetchTasksTriggered, setFetchTasksTriggered] = useState(false);
  const [fetchMailTriggered, setFetchMailTriggered] = useState(false);
  const [fetchAveragesTriggered, setFetchAveragesTriggered] = useState(false);

  const refetchHandler = () => {
    //  set details to undefined so that spinner will be displayed and
    // fetchUserDetails will be invoked from useEffect
    setUserContext((oldValues) => {
      return { ...oldValues, details: undefined };
    });
  };

  const fetchTasks = () => {
    if (fetchTasksTriggered === false) {
      //console.log("Called fetch tasks");
      switch (userContext.details.team) {
        case "Photographers":
          const url =
            process.env.REACT_APP_API_ENDPOINT + "feedback/record/count";
          // //console.log(userContext.details.firstName + " " + userContext.details.lastName)
          axios({
            url: url,
            params: {
              photographerName:
                userContext.details.firstName +
                " " +
                userContext.details.lastName,
            },
            method: "GET",
          })
            .then((response) => {
              setTasksData(response.data);
            })
            .catch((err) => {
              console.log(err.message);
            });
          break;

        default:
          const url4 =
            process.env.REACT_APP_API_ENDPOINT + "feedback/record/count";
          // //console.log(userContext.details.firstName + " " + userContext.details.lastName)
          axios({
            url: url4,
            params: {
              photographerName:
                userContext.details.firstName +
                " " +
                userContext.details.lastName,
            },
            method: "GET",
          })
            .then((response) => {
              ////console.log(response.data)
              setTasksData(response.data);
            })
            .catch((err) => {
              //console.log(err.message);
            });
          break;
      }
      setFetchTasksTriggered(true);
    }
  };

  let responseArray = [];

  const fetchAverages = () => {
    if (fetchAveragesTriggered === false) {
      //console.log("Called fetch averages");
      switch (userContext.details.team) {
        case "Photographers":
          const url =
            process.env.REACT_APP_API_ENDPOINT + "feedback/record/home-stats";
          // //console.log(userContext.details.firstName + " " + userContext.details.lastName)
          axios({
            url: url,
            params: {
              photographerName:
                userContext.details.firstName +
                " " +
                userContext.details.lastName,
            },
            method: "GET",
          })
            .then((response) => {
              ////console.log(response.data)
              // do something with the data

              let ratingFive = 0;
              let ratingFour = 0;
              let ratingThree = 0;
              let ratingTwo = 0;
              let ratingOne = 0;
              let numberFeedbackUnread = 0;

              for (let i = 0; i < response.data.length; i++) {
                if (response.data[i].feedbackRead === false) {
                  numberFeedbackUnread++;
                }

                responseArray.push(response.data[i].overallJobRating);

                // while we're in the loop anyway, get a count of each type of feedback, and save to a different list
                switch (response.data[i].overallJobRating) {
                  case 5:
                    ratingFive++;
                    break;
                  case 4:
                    ratingFour++;
                    break;
                  case 3:
                    ratingThree++;
                    break;
                  case 2:
                    ratingTwo++;
                    break;
                  case 1:
                    ratingOne++;
                    break;
                  default:
                  // break;
                }
              }

              var total = 0;
              for (var i = 0; i < responseArray.length; i++) {
                total += responseArray[i];
              }
              var avg = (total / responseArray.length) * 100;

              var outOf100 = Math.floor(
                ((total / responseArray.length) * 100) / 5
              );
              ////console.log(outOf100);

              setFeedbackAverages(outOf100);

              // now, write the feedback averages back into the user's db entry so it can be retrieved later for reports
              //

              //
              // now, write the feedback averages back into the user's db entry so it can be retrieved later for reports

              // set the rating counts that we worked out earlier, so we can display these in a feedback breakdown
              setRatingFiveCount(ratingFive);
              setRatingFourCount(ratingFour);
              setRatingThreeCount(ratingThree);
              setRatingTwoCount(ratingTwo);
              setRatingOneCount(ratingOne);
              setNumberFeedbackUnread(numberFeedbackUnread);
            })
            .catch((err) => {
              console.log(err.message);
            });
          break;

        default:
          const url4 =
            process.env.REACT_APP_API_ENDPOINT + "feedback/record/home-stats";
          // //console.log(userContext.details.firstName + " " + userContext.details.lastName)
          axios({
            url: url4,
            params: {
              photographerName:
                userContext.details.firstName +
                " " +
                userContext.details.lastName,
            },
            method: "GET",
          })
            .then((response) => {
              //console.log(response.data)
              // setTasksData(response.data);
            })
            .catch((err) => {
              console.log(err.message);
            });
          break;
      }

      setFetchAveragesTriggered(true);
    }
  };

  const fetchMail = () => {
    if (fetchMailTriggered === false) {
      //console.log("Called fetch mail")

      const url =
        process.env.REACT_APP_API_ENDPOINT + "messaging/message/count";
      //console.log(userContext.details.firstName + " " + userContext.details.lastName)
      axios({
        url: url,
        params: {
          recipientName:
            userContext.details.firstName + " " + userContext.details.lastName,
        },
        method: "GET",
      })
        .then((response) => {
          ////console.log(response.data)
          // do something with the data
          setUnreadMessagesCount(response.data);
          localStorage.setItem("unreadMessageCount", response.data);
        })
        .catch((err) => {
          //console.log(err.message);
        });

      setFetchMailTriggered(true);
      // console.log(unreadMessagesCount);
    }
  };

  const checkFeedbackAmount = () => {
    ////console.log("Called fetch tasks")
    if (numberFeedbackUnread >= 10) {
      return (
        <div>
          <Card>
            <Alert severity="error">
              The amount of unread feedback for your user account is too high.
            </Alert>
            <br></br>
            <p>
              <strong>
                Please read your feedback - it's to ensure we all get heard and
                improve as a team.
              </strong>
            </p>
          </Card>
          <br></br>
        </div>
      );
    } else {
      // do nothing
    }
  };

  const displayAverageFeedbackScore = () => {
    if (userContext.details.team === "Photographers") {
      if (feedbackAverages >= 80) {
        return (
          <>
            <Card style={{ backgroundColor: blueGrey[500], color: "white" }}>
              <CardContent>
                <h2>Average Feedback Score</h2>
                <h6></h6>
                <div className="feedbackPointsGreen">
                  <p className="feedbackPercentage">
                    <strong>{feedbackAverages}</strong> %
                  </p>
                </div>
                <Alert severity="success">
                  Your average feedback score is good - keep up the good work!
                </Alert>
              </CardContent>
            </Card>
            <Card style={{ backgroundColor: blueGrey[500], color: "white" }}>
              <CardContent>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>How is this score calculated?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <ul>
                        <li>
                          Your termly score is calculated by working out the
                          average of all the feedback you have received from
                          both Andrew and the lab.{" "}
                        </li>
                        <br></br>
                        <li>
                          Each job is scored out of a maximum rating of 5, with
                          5 being perfect and 1 being poor. A green score over
                          90% is considered excellent, with amber and red being
                          issued if feedback is too poor.
                        </li>
                        <br></br>
                        <li>
                          The better your overall feedback in each category, the
                          better your overall average will be.
                        </li>
                        <br></br>
                      </ul>
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Why is my score low?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <ul>
                        <li>
                          The better your overall feedback in each category, the
                          better your overall average will be. If you haven't
                          shot many jobs in a term, but your feedback for those
                          few jobs wasn't high, this rating will be calculated
                          to a low number. As you improve over the term, this
                          will then reach a positive grade again.
                        </li>
                        <br></br>
                        <li>
                          If you have just started shooting, it takes a while
                          for an average to settle. The portal doesn't report on
                          your average to the lab until you've been on a week's
                          worth of shoots.
                        </li>
                        <br></br>
                        <li>
                          Not reading feedback regularly automatically lowers
                          your score by 1% each time you don't read feedback
                          within a realistic time frame, so ensure you read your
                          feedback regularly.
                        </li>
                        <br></br>
                      </ul>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </CardContent>
            </Card>{" "}
            <br></br>{" "}
            <Card>
              <CardContent>
                <h2>Feedback Breakdown</h2>
                <h6>Total Feedback: {tasksData} reviews</h6>

                <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-gray-100">
                      <Table className="specialCursor min-w-full divide-y divide-gray-200">
                        <Thead className="bg-gray-50">
                          <Tr>
                            <Th className="px-6 py-3 text-left text-medium font-medium text-gray-500 uppercase tracking-wider">
                              Outstanding{" "}
                            </Th>
                            <Th className="px-6 py-3 text-left text-medium font-medium text-gray-500 uppercase tracking-wider">
                              Good
                            </Th>
                            <Th className="px-6 py-3 text-left text-medium font-medium text-gray-500 uppercase tracking-wider">
                              Average
                            </Th>
                            <Th className="px-6 py-3 text-left text-medium font-medium text-gray-500 uppercase tracking-wider">
                              Bad
                            </Th>
                            <Th className="px-6 py-3 text-left text-medium font-medium text-gray-500 uppercase tracking-wider">
                              Poor
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody className="bg-white divide-y divide-gray-200">
                          <Tr>
                            <Td className="px-6 py-3 whitespace-nowrap">
                              <img
                                src={ratingFive}
                                alt="outstanding_face"
                              ></img>
                              &nbsp;
                              <strong>{ratingFiveCount}</strong>
                            </Td>

                            <Td className="px-6 py-3 whitespace-nowrap">
                              <img src={ratingFour} alt="patch_notes"></img>
                              &nbsp;
                              <strong>{ratingFourCount}</strong>
                            </Td>

                            <Td className="px-6 py-3 whitespace-nowrap">
                              <img src={ratingThree} alt="patch_notes"></img>
                              &nbsp;
                              <strong>{ratingThreeCount}</strong>
                            </Td>
                            <Td className="px-6 py-3 whitespace-nowrap">
                              <img src={ratingTwo} alt="patch_notes"></img>
                              &nbsp;
                              <strong>{ratingTwoCount}</strong>
                            </Td>
                            <Td className="px-6 py-3 whitespace-nowrap">
                              <img src={ratingOne} alt="patch_notes"></img>
                              &nbsp;
                              <strong>{ratingOneCount}</strong>
                            </Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </>
        );
      } else if (feedbackAverages >= 70 && feedbackAverages <= 79) {
        return (
          <>
            <Card style={{ backgroundColor: blueGrey[500], color: "white" }}>
              <CardContent>
                <h2>Average Feedback Score</h2>
                <div className="feedbackPointsAmber">
                  <p className="feedbackPercentage">
                    <strong>{feedbackAverages}</strong> %
                  </p>
                </div>
                <Alert severity="warning">
                  Your feedback score needs improvement - please work with
                  Andrew and take onboard feedback from the lab
                </Alert>
              </CardContent>
            </Card>
            <Card style={{ backgroundColor: blueGrey[500], color: "white" }}>
              <CardContent>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>How is this score calculated?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <ul>
                        <li>
                          Your termly score is calculated by working out the
                          average of all the feedback you have received from
                          both Andrew and the lab.{" "}
                        </li>
                        <br></br>
                        <li>
                          Each job is scored out of a maximum rating of 5, with
                          5 being perfect and 1 being poor. A green score over
                          90% is considered excellent,management-stats The
                          better your overall feedback in each category, the
                          better your overall average will be.
                        </li>
                        <br></br>
                      </ul>
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Why is my score low?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <ul>
                        <li>
                          The better your overall feedback in each category, the
                          better your overall average will be. If you haven't
                          shot many jobs in a term, but your feedback for those
                          few jobs wasn't high, this rating will be calculated
                          to a low number. As you improve over the term, this
                          will then reach a positive grade again.
                        </li>
                        <br></br>
                        <li>
                          If you have just started shooting, it takes a while
                          for an average to settle. The portal doesn't report on
                          your average to the lab until you've been on a week's
                          worth of shoots.
                        </li>
                        <br></br>
                        <li>
                          Not reading feedback regularly automatically lowers
                          your score by 1% each time you don't read feedback
                          within a realistic time frame, so ensure you read your
                          feedback regularly.
                        </li>
                        <br></br>
                      </ul>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </CardContent>
            </Card>
            <br></br>
            <Card>
              <CardContent>
                <h2>Feedback Breakdown</h2>
                <h6>Total Feedback: {tasksData} reviews</h6>

                <div>
                  <Table class="styled-table">
                    <Thead>
                      <Tr>
                        <Th>Outstanding </Th>
                        <Th>Good</Th>
                        <Th>Average</Th>
                        <Th>Bad</Th>
                        <Th>Poor</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>
                          <img src={ratingFive} alt="patch_notes"></img>&nbsp;
                          <strong>{ratingFiveCount}</strong>
                        </Td>

                        <Td>
                          <img src={ratingFour} alt="patch_notes"></img>&nbsp;
                          <strong>{ratingFourCount}</strong>
                        </Td>

                        <Td>
                          <img src={ratingThree} alt="patch_notes"></img>&nbsp;
                          <strong>{ratingThreeCount}</strong>
                        </Td>
                        <Td>
                          <img src={ratingTwo} alt="patch_notes"></img>&nbsp;
                          <strong>{ratingTwoCount}</strong>
                        </Td>
                        <Td>
                          <img src={ratingOne} alt="patch_notes"></img>&nbsp;
                          <strong>{ratingOneCount}</strong>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </div>
              </CardContent>
            </Card>
          </>
        );
      } else {
        return (
          <>
            <Card style={{ backgroundColor: blueGrey[500], color: "white" }}>
              <CardContent>
                <h2>Average Feedback Score</h2>
                <div className="feedbackPointsRed">
                  <p className="feedbackPercentage">
                    <strong>{feedbackAverages}</strong> %
                  </p>
                </div>
                <Alert severity="error">
                  Please report to the lab, and work with Andrew and Jeni on how
                  to improve your work
                </Alert>
              </CardContent>
            </Card>
            <Card style={{ backgroundColor: blueGrey[500], color: "white" }}>
              <CardContent>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>How is this score calculated?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <ul>
                        <li>
                          Your termly score is calculated by working out the
                          average of all the feedback you have received from
                          both Andrew and the lab.
                        </li>
                        <br></br>
                        <li>
                          Each job is scored out of a maximum rating of 5, with
                          5 being perfect and 1 being poor. A green score over
                          90% is considered excellent, with amber and red being
                          issued if feedback is too poor.
                        </li>
                        <br></br>
                        <li>
                          The better your overall feedback in each category, the
                          better your overall average will be.
                        </li>
                        <br></br>
                      </ul>
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Why is my score low?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <ul>
                        <li>
                          The better your overall feedback in each category, the
                          better your overall average will be. If you haven't
                          shot many jobs in a term, but your feedback for those
                          few jobs wasn't high, this rating will be calculated
                          to a low number. As you improve over the term, this
                          will then reach a positive grade again.
                        </li>
                        <br></br>
                        <li>
                          If you have just started shooting, it takes a while
                          for an average to settle. The portal doesn't report on
                          your average to the lab until you've been on a week's
                          worth of shoots.
                        </li>
                        <br></br>
                        <li>
                          Not reading feedback regularly automatically lowers
                          your score by 1% each time you don't read feedback
                          within a realistic time frame, so ensure you read your
                          feedback regularly.
                        </li>
                        <br></br>
                      </ul>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </CardContent>
            </Card>
            <br></br>
            <Card>
              <CardContent>
                <h2>Feedback Breakdown</h2>
                <h6>Total Feedback: {tasksData} reviews</h6>

                <div>
                  <Table class="styled-table">
                    <Thead>
                      <Tr>
                        <Th>Outstanding </Th>
                        <Th>Good</Th>
                        <Th>Average</Th>
                        <Th>Bad</Th>
                        <Th>Poor</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>
                          <img src={ratingFive} alt="patch_notes"></img>&nbsp;
                          <strong>{ratingFiveCount}</strong>
                        </Td>

                        <Td>
                          <img src={ratingFour} alt="patch_notes"></img>&nbsp;
                          <strong>{ratingFourCount}</strong>
                        </Td>

                        <Td>
                          <img src={ratingThree} alt="patch_notes"></img>&nbsp;
                          <strong>{ratingThreeCount}</strong>
                        </Td>
                        <Td>
                          <img src={ratingTwo} alt="patch_notes"></img>&nbsp;
                          <strong>{ratingTwoCount}</strong>
                        </Td>
                        <Td>
                          <img src={ratingOne} alt="patch_notes"></img>&nbsp;
                          <strong>{ratingOneCount}</strong>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </div>
              </CardContent>
            </Card>
          </>
        );
      }
    }
  };

  const checkCurrentTeam = () => {
    ////console.log("Called fetch tasks")
    if (userContext.details.team === "Photographers") {
      return (
        <>
          <p>
            {" "}
            <ForumTwoToneIcon
              sx={{ color: purple[800], fontSize: 30 }}
            ></ForumTwoToneIcon>
            &nbsp;&nbsp; You have &nbsp;
            <NavLink to="/view-feedback">
              <strong style={{ color: "red", fontSize: 20 }}>
                {numberFeedbackUnread}
              </strong>{" "}
            </NavLink>{" "}
            &nbsp;new feedback entries to review
          </p>
        </>
      );
    }
  };

  const displayManagementStats = () => {
    if (
      userContext.details.firstName === "Richard" ||
      userContext.details.firstName === "Adam" ||
      userContext.details.firstName === "Andrew"
    ) {
      return (
        <table>
          <Tr>
            <th>Outstanding </th>
            <th>Good</th>
            <th>Average</th>
            <th>Bad</th>
            <th>Poor</th>
          </Tr>
          <Tr>
            <Td>
              <img src={ratingFive} alt="patch_notes"></img>&nbsp;
              <strong>{ratingFiveCount}</strong>
            </Td>

            <Td>
              <img src={ratingFour} alt="patch_notes"></img>&nbsp;
              <strong>{ratingFourCount}</strong>
            </Td>

            <Td>
              <img src={ratingThree} alt="patch_notes"></img>&nbsp;
              <strong>{ratingThreeCount}</strong>
            </Td>
            <Td>
              <img src={ratingTwo} alt="patch_notes"></img>&nbsp;
              <strong>{ratingTwoCount}</strong>
            </Td>
            <Td>
              <img src={ratingOne} alt="patch_notes"></img>&nbsp;
              <strong>{ratingOneCount}</strong>
            </Td>
          </Tr>
        </table>
      );
    }
  };

  const updateFeedbackAverages = () => {
    if (unreadMessagesCount !== "" && feedbackAverages !== "") {
      console.log("All data present and correct - submitting put request now");

      const url =
        process.env.REACT_APP_API_ENDPOINT + "feedback/record/userupdate";

      axios({
        url: url,
        data: {
          _id: userContext.details._id,
          photographerName:
            userContext.details.firstName + " " + userContext.details.lastName,
          averageFeedbackRating: feedbackAverages,
          feedbackReceived: tasksData,
          excellentFeedback: ratingFiveCount,
          goodFeedback: ratingFourCount,
          averageFeedback: ratingThreeCount,
          badFeedback: ratingTwoCount,
          poorFeedback: ratingOneCount,
          unreadCount: numberFeedbackUnread,
        },
        method: "put",
      })
        .then((response) => {
          //console.log(response.data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      console.log(
        "ERR - Some data was missing from the updateFeedbackAverages request so nothing was posted"
      );
    }

    setUpdateFeedbackTriggered(true);
  };

  useEffect(() => {
    // fetch only when user details are not present
    if (!userContext.details) {
    } else {
      setTimeout(() => {
        // console.log("Reached use effect else and calling fetch tasks")
        fetchTasks();
        fetchAverages();
        fetchMail();
        updateFeedbackAverages();
        //updateFeedbackAverages();
      }, 0);
    }
  }, [userContext, feedbackAverages, unreadMessagesCount]);

  //#region
  return userContext.details === null ? (
    "Error Loading User details"
  ) : !userContext.details ? (
    <Loader />
  ) : (
    <div>
      <Header unreadCountToPass={unreadMessagesCount}></Header>
      <div class="container-fluid body">
        <div class="row">
          <div class="col-sm">
            <div class="user-details">
              <Card>
                <CardContent>
                  <h2>Profile</h2>
                  <br></br>

                  <Avatar
                    name={
                      userContext.details.firstName +
                      " " +
                      userContext.details.lastName
                    }
                  />
                  <div className="profileDetails">
                    <h5>
                      <strong>
                        {userContext.details.firstName}{" "}
                        {userContext.details.lastName}
                      </strong>
                    </h5>
                    <h6>Assigned Team: {userContext.details.team}</h6>
                  </div>
                  <br></br>

                  <br></br>
                  <br></br>

                  {displayAverageFeedbackScore()}

                  <br></br>
                </CardContent>
              </Card>
            </div>
          </div>
          <div class="col-sm">
            <div class="user-details">
              <Card>
                <CardContent>
                  <h2>Tasks</h2>
                  <br></br>
                  {checkCurrentTeam()}

                  {checkFeedbackAmount()}
                  <p>
                    &nbsp;
                    <EmailTwoToneIcon
                      sx={{ color: purple[800], fontSize: 30 }}
                    ></EmailTwoToneIcon>
                    &nbsp;&nbsp; You have &nbsp;
                    <NavLink to="/inbox">
                      <strong style={{ color: "red", fontSize: 20 }}>
                        {unreadMessagesCount}
                      </strong>
                    </NavLink>
                    &nbsp; new mail messages
                  </p>
                </CardContent>
              </Card>
              <br></br>
              <Card className="scroll">
                <CardContent className="scroll">
                  <h2>Training Focus</h2>
                  <br></br>
                  <Card>
                    <CardContent>
                      <h6>
                        <SchoolTwoToneIcon
                          sx={{ color: purple[800] }}
                        ></SchoolTwoToneIcon>
                        &nbsp;&nbsp; From: Portal Admin, Autumn 2023 - Feedback
                        Introduction
                      </h6>
                      <div>
                        <ul>
                          <br></br>
                          <li>
                            We are one team, working towards getting photos to
                            our customers as soon as we can. Feedback is not
                            there to criticise your job performance - it is a
                            productivity tool to help ensure we do this as best
                            we can
                          </li>
                          <br></br>
                          <li>
                            Your work is judged on how much time a job takes to
                            edit/clean up for the lab. Good jobs = less time
                            editing = good feedback
                          </li>
                          <br></br>
                          <li>
                            Check your messages: Andrew may first send you a
                            message asking you to see him/change something
                            rather than award bad feedback
                          </li>
                          <br></br>
                          <li>
                            Feedback will no longer be changed or removed - this
                            functionality has been completely removed so that no
                            one can influence feedback. We welcome discussion on
                            how feedback can be fair and accurate - share your
                            thoughts on this with Peter and Andrew
                          </li>
                        </ul>

                        <p></p>
                      </div>
                    </CardContent>
                  </Card>
                  <br></br>
                </CardContent>
              </Card>
              <br></br>
            </div>
          </div>
          <div class="col-sm">
            <div class="user-details">
              <Card className="scroll-update">
                <CardContent className="scroll-update">
                  <h2>Notice Board</h2>
                  <br></br>
                  <Card>
                    <CardContent>
                      <h6>
                        <UpdateTwoToneIcon
                          sx={{ color: purple[800] }}
                        ></UpdateTwoToneIcon>
                        &nbsp;&nbsp;Sunday 10th September - Update notes
                      </h6>
                      <br></br>
                      <ul>
                        Hey all, <br></br>
                        <br></br>
                        Thanks for your patience with the recent downtime - the
                        portal is now up to 35 active users! We have upgraded
                        the server to cope with the additional load so hopefully
                        there will not be any more portal outages going forward.
                        Just remember there is a portal time out after 3 hours
                        so you may want to plug your laptop directly into your
                        router to help the transfer go faster. If there is any
                        downtime you notice on the portal, please send me a
                        message when it is working again, and I will increase
                        the server resources.
                        <br></br>
                        <br></br>
                        Thanks!
                        <br></br>
                        <br></br>
                        Update notes for this version:
                        <br></br>
                        <br></br>
                        <li>
                          <li>Changed - increased server resources</li>
                          <li>Changed - removed old training notices</li>
                          <li>Changed - reset all scores</li>
                          <li>Changed - added new training information</li>
                          <li>
                            Removed - feedback edit and delete functionality
                          </li>
                        </li>
                      </ul>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardContent>
                      <h6>
                        <UpdateTwoToneIcon
                          sx={{ color: purple[800] }}
                        ></UpdateTwoToneIcon>
                        &nbsp;&nbsp;Wednesday 10th May - Update notes
                      </h6>
                      <br></br>
                      <ul>
                        <li>
                          <strong>New feature</strong> - you can now submit your
                          timesheets and expenses through the portal
                        </li>
                        <li>
                          <strong>New feature</strong> - the portal now fully
                          works on mobile devices
                        </li>
                        <br></br>
                        <li>
                          Changed - you will be logged out of the portal after 4
                          hours of inactivity. Please give feedback if this
                          isn't enough time to finish filemail transfers
                        </li>
                        <li>Changed - styling changes</li>
                        <li>
                          Changed - all scores now reset and the table will now
                          only show photographers with more than a certain
                          amount of feedback to make it fairer
                        </li>
                        <li>
                          Changed - files menu is now a complete menu instead of
                          a dropdown
                        </li>
                        <li>
                          Changed - there is now a security certificate on the
                          portal
                        </li>
                        <br></br>
                        <li>
                          Fixed - you will now get a success message when your
                          timesheets send succesfully
                        </li>
                      </ul>
                    </CardContent>
                  </Card>
                  <br></br>
                  <Card>
                    <CardContent>
                      <h6>
                        <AnnouncementTwoToneIcon
                          sx={{ color: purple[800] }}
                        ></AnnouncementTwoToneIcon>
                        &nbsp;&nbsp;From: <strong>Andrew</strong>, Friday 5th
                        May
                      </h6>
                      <br></br>
                      <p>
                        Hi all,<br></br>
                        <br></br> Just wanted to say that you are all doing a
                        fantastic job, and the quality of the photography is
                        much improved. Remember, I am always here if you need to
                        come and see me for advice. Your average score is there
                        to help, not to criticise.<br></br>
                        <br></br>
                        Andrew
                      </p>
                    </CardContent>
                  </Card>
                  <br></br>
                  <Card>
                    <CardContent>
                      <h6>
                        <UpdateTwoToneIcon
                          sx={{ color: purple[800] }}
                        ></UpdateTwoToneIcon>
                        &nbsp;&nbsp;Tuesday 2nd May - Update notes
                      </h6>
                      <br></br>
                      <br></br>
                      <ul>
                        <li>
                          <strong>New feature</strong> - you will now get an
                          email when you receive a new message on the portal
                        </li>
                        <li>
                          <strong>New feature</strong> - you can now reply to a
                          message, instead of having to write a new message
                          every time
                        </li>
                        <li>
                          <strong>New feature</strong> - you now open your
                          feedback and messages by clicking on the feedback row.
                          The separate open buttons have now been removed
                        </li>
                        <br></br>

                        <li>Added - privacy and cookie policy</li>
                        <br></br>
                        <li>Changed - styling on messaging buttons</li>
                        <li>
                          Changed - files is now a drop down menu for CSV & PDF
                          files
                        </li>
                        <li>Changed - spacing reduced on nav bar</li>
                        <li>Changed - perfomance optimisation</li>
                        <li>Changed - colour of buttons</li>
                        <br></br>
                        <li>
                          Fixed - data files and cards not uploading to the
                          portal
                        </li>
                        <li>
                          Fixed - shoot and feedback dates displaying
                          incorrectly
                        </li>
                        <li>
                          Fixed - resizing messaging screen made things get cut
                          off
                        </li>
                        <li>Fixed - menu in messaging page cutting off</li>
                      </ul>
                    </CardContent>
                  </Card>
                  <br></br>
                  <Card>
                    <CardContent>
                      <h6>
                        <AnnouncementTwoToneIcon
                          sx={{ color: purple[800] }}
                        ></AnnouncementTwoToneIcon>
                        &nbsp;&nbsp;From: <strong>Adam</strong>, Monday 24th
                        March
                      </h6>
                      <br></br>
                      <br></br>
                      <p>Hi all,</p>
                      <p>
                        If you forget your data cards for either portraits or
                        groups (which is a waste of Julia's time), you will be
                        scored <strong>zero</strong> under the data category.
                      </p>
                    </CardContent>
                  </Card>
                  <br></br>

                  <Card>
                    <CardContent>
                      <h6>
                        <AnnouncementTwoToneIcon
                          sx={{ color: purple[800] }}
                        ></AnnouncementTwoToneIcon>
                        &nbsp;&nbsp;From: <strong>Andrew</strong>, Monday 13th
                        March
                      </h6>
                      <br></br>
                      <br></br>
                      <p>Hi all,</p>
                      <p>
                        Can you please ensure that you keep photographing the
                        grey card, with someone holding it up. You DON'T need to
                        use it to set your white balance, just take a photograph
                        so we have it.
                      </p>
                      <p>Thanks!</p>
                    </CardContent>
                  </Card>
                  <br></br>

                  <br></br>
                  <Card>
                    <CardContent>
                      <h6>
                        <UpdateTwoToneIcon
                          sx={{ color: purple[800] }}
                        ></UpdateTwoToneIcon>
                        &nbsp;&nbsp;Monday 27th February - Update notes
                      </h6>
                      <br></br>
                      <br></br>
                      <ul>
                        <li>
                          Feedback sorted by newest feedback first, as requested
                        </li>
                        <li>
                          You can now mark data feedback as "Not applicable"
                          when leaving feedback
                        </li>
                        <li>
                          Validation added to feedback, it will now tell you if
                          you've missed a field
                        </li>
                        <li>Styling on tables</li>
                        <li>
                          Functionality to see who is/isn't reading feedback
                          added
                        </li>
                        <li>Dates now formatted as UK dates</li>
                        <li>Mail preview icon added</li>
                        <li>Manuals updated</li>
                      </ul>
                    </CardContent>
                  </Card>
                  <br></br>
                  <Card>
                    <CardContent>
                      <h6>
                        <UpdateTwoToneIcon
                          sx={{ color: purple[800] }}
                        ></UpdateTwoToneIcon>
                        &nbsp;&nbsp;Thursday 16th February - Update notes
                      </h6>
                      <br></br>
                      <br></br>
                      <ul>
                        <li>
                          Messaging system complete - you can now send and
                          receive messages from the lab. Andrew will send you
                          messages if something requires your urgent attention.
                        </li>
                        <li>Filemail reliability is now 100%, 24/7</li>
                        <li>
                          Beta warnings removed - the portal is now feature
                          complete. Mileage feature has been removed for now and
                          will be revisited at a later date
                        </li>
                      </ul>
                    </CardContent>
                  </Card>
                  <br></br>
                </CardContent>
              </Card>
              <br></br>

              <br></br>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Home;
