//import { Card, Tab, Tabs } from "@blueprintjs/core"
import Card from "@mui/material/Card";
import { useCallback, useContext, useEffect, useState } from "react";
import * as React from "react";
import { Route, useHistory } from "react-router-dom";
import { UserContext } from "./context/UserContext";
import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Home";
import Schedules from "./components/Schedules";
import StudentDataFiles from "./components/StudentDataFiles";
import StudentCardFiles from "./components/StudentCardFiles";
import Feedback from "./components/Feedback";
import TransferWork from "./components/TransferWork";
import TransferWorkEnterInfo from "./components/TransferWorkEnterInfo";
import EnterFeedback from "./components/EnterFeedback";
import ViewFeedback from "./components/ViewFeedback";
import ViewFeedbackEntry from "./components/ViewFeedbackEntry";
import Resources from "./components/Resources";
import ManagementStats from "./components/ManagementStats";
import Timesheets from "./components/Timesheets";
import PrivacyPolicy from "./components/PrivacyPolicy";
import CookiePolicy from "./components/CookiePolicy";
import Compose from "./components/Compose";
import ComposeReply from "./components/ComposeReply";
import Inbox from "./components/Inbox";
import SentItems from "./components/SentItems";
import Files from "./components/Files";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function App() {
  const [currentTab, setCurrentTab] = useState("login");
  const [userContext, setUserContext] = useContext(UserContext);
  const history = useHistory();

  const verifyUser = useCallback(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "users/refreshToken", {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    }).then(async (response) => {
      if (response.ok) {
        const data = await response.json();
        setUserContext((oldValues) => {
          return { ...oldValues, token: data.token };
        });
      } else {
        setUserContext((oldValues) => {
          return { ...oldValues, token: null };
        });
      }
      // call refreshToken to renew the authentication token.
      setTimeout(verifyUser, 240 * 60 * 1000);
    });
  }, [setUserContext]);

  useEffect(() => {
    verifyUser();
  }, [verifyUser]);

  const fetchUserDetails = useCallback(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "users/me", {
      method: "GET",
      credentials: "include",
      //Pass authentication token as bearer token in header
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userContext.token}`,
      },
    }).then(async (response) => {
      if (response.ok) {
        const data = await response.json();
        setUserContext((oldValues) => {
          return { ...oldValues, details: data };
        });
      } else {
        if (response.status === 401) {
          // Edge case: when the token has expired.
          //This could happen if the refreshToken calls have failed due to network error or
          // User has had the tab open from previous day and tries to click on the Fetch button
          //window.location.reload()
        } else {
          setUserContext((oldValues) => {
            return { ...oldValues, details: null };
          });
        }
      }
    });
  }, [setUserContext, userContext.token]);

  useEffect(() => {
    // fetch only when user details are not present
    if (!userContext.details) {
      fetchUserDetails();
    }
  }, [userContext.details, fetchUserDetails]);

  const logoutHandler = () => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "users/logout", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userContext.token}`,
      },
    }).then(async (response) => {
      setUserContext((oldValues) => {
        return { ...oldValues, details: undefined, token: null };
      });
      window.localStorage.setItem("logout", Date.now());

      // navigate back to home
      history.push({
        pathname: "/",
      });
    });
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  // })
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return userContext.token === null ? (
    <div>
      <div className="splashHeader">
        <img
          src="http://colorfoto.net/wp-content/uploads/2020/01/colorfoto-logo-01.png"
          width="300"
          alt=""
        />
        <br></br>
        <br></br>
        <h2>Photographer Portal</h2>
      </div>
      <Card className="loginRegisterCard">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Login" {...a11yProps(0)} />
          <Tab label="Register" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Login></Login>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Register></Register>
        </TabPanel>
      </Card>
    </div>
  ) : userContext.token ? (
    //<div>Cut and paste the lines between marks "START" "END" here and then uncomment top lines to re-enable login functionality</div>
    //START
    <div>
      <div>
        <Route
          exact
          path="/"
          render={(props) => <Home text={"Test"} {...props} />}
        />
        <Route path="/resources" component={Resources} />
        <Route path="/schedules" component={Schedules} />
        <Route path="/files" component={Files} />
        <Route path="/student-data-files" component={StudentDataFiles} />
        <Route path="/student-card-files" component={StudentCardFiles} />
        <Route path="/feedback" component={Feedback} />
        <Route path="/transfer" component={TransferWork} />
        <Route path="/transfer-intro" component={TransferWorkEnterInfo} />
        <Route path="/compose" component={Compose} />
        <Route path="/compose-reply" component={ComposeReply} />
        <Route path="/inbox" component={Inbox} />
        <Route path="/sent-items" component={SentItems} />
        <Route path="/timesheets" component={Timesheets} />
        <Route path="/enter-feedback" component={EnterFeedback} />
        <Route path="/view-feedback" component={ViewFeedback} />
        <Route path="/view-feedback-entry" component={ViewFeedbackEntry} />
        <Route path="/management-stats" component={ManagementStats} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/cookie-policy" component={CookiePolicy} />
      </div>
    </div>
  ) : (
    <div>
      <div className="splashHeader">
        <img
          src="http://colorfoto.net/wp-content/uploads/2020/01/colorfoto-logo-01.png"
          width="300"
          alt=""
        />
        <br></br>
        <br></br>
        <h2>Photographer Portal</h2>
      </div>
      <Card className="loginRegisterCard">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Login" {...a11yProps(0)} />
          <Tab label="Register" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Login></Login>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Register></Register>
        </TabPanel>
      </Card>

      {/* <Card className="loginRegisterCard" elevation="1">

        <Tabs id="Tabs" onChange={setCurrentTab} selectedTabId={currentTab} className="loginRegisterSection">

          <Tab id="login" title="Login" panel={<Login />} />
          <Tab id="register" title="Register" panel={<Register />} />
          <Tabs.Expander />
        </Tabs>
      </Card> */}
    </div>
  );
}

export default App;
