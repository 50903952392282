import React, { useState } from "react";
import { Document, Page } from "react-pdf";

export default function SinglePage(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const { pdf } = props;

  return (
    <>
      <div className="buttons-container">
        <button
          type="button"
          disabled={pageNumber <= 1}
          onClick={previousPage}
          className="btn btn-primary m-2"
        >
          Previous Page
        </button>

        <p className="m-3">
          Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
        </p>

        <button
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
          className="btn btn-primary m-2"
        >
          Next Page
        </button>
      </div>
      <div id="ResumeContainer">
        <Document
          file={pdf}
          options={{ workerSrc: "/pdf.worker.js" }}
          onLoadSuccess={onDocumentLoadSuccess}
          className={"PDFDocument"}
        >
          <Page pageNumber={pageNumber} className={"PDFPage"} />
        </Document>
      </div>
    </>
  );
}
