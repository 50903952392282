import React, { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import axios from "axios";
import { useTable, useFilters, usePagination, useSortBy } from "react-table";
import Header from "../components/Header";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { PageButton } from "../components/Button";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import Moment from "moment";
import SentModal from "./SentModal";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import MailIcon from "@mui/icons-material/Mail";
import { red } from "@mui/material/colors";
import Card from "@mui/material/Card";
import { Table, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

const SentItems = (props) => {
  const [data, setData] = useState([]);
  const initialFormData = Object.freeze({
    recipientName: "",
    senderName: "",
    subject: "",
    message: "",
  });
  const [formData, updateFormData] = React.useState(initialFormData);
  const [userContext, setUserContext] = useContext(UserContext);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const [modalData, setModalData] = useState({
    recipient: "Title goes here",
    messageSendDate: "Send date goes here",
    subject: "Subject goes here",
    message: "Body goes here",
  });

  useEffect(() => {
    if (!userContext.details) {
      fetchUserDetails();
    } else {
      fetchMail();
    }
  }, [modalData]);

  const selectRows = (row) => {
    // set selected rows so we can mark mail as read later
    setSelectedRows({ selectedRows: row.original });
    //  setMessage({ selectedRows: row.original })

    //console.log(row.original.messageSendDate)

    setModalData({
      // quick code here - we just want to swap sender/recipient so that we are showing the recipient here,
      // instead of having to re-code the modal to serve up dynamically
      recipient: row.original.recipientName,
      messageSendDate: row.original.messageSendDate,
      subject: row.original.subject,
      message: row.original.message,
    });
  };

  // 2) When setModalData async has finished, log the data to the console
  useEffect(() => {
    //console.log(modalData)
    if (isFirstRender === false) {
      // open the modal
      setIsOpen(true);
    }

    if (isFirstRender === true) {
      setIsFirstRender(false);
    }
  }, [modalData]);

  const handleChange = (e) => {
    //console.log(e.target.value)
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]: e.target.value,
    });
  };

  const fetchUserDetails = useCallback(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "users/me", {
      method: "GET",
      credentials: "include",
      //Pass authentication token as bearer token in header
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userContext.token}`,
      },
    }).then(async (response) => {
      if (response.ok) {
        const data = await response.json();
        setUserContext((oldValues) => {
          return { ...oldValues, details: data };
        });
      } else {
        if (response.status === 401) {
          // Edge case: when the token has expired.
          //This could happen if the refreshToken calls have failed due to network error or
          // User has had the tab open from previous day and tries to click on the Fetch button
          //window.location.reload()
        } else {
          setUserContext((oldValues) => {
            return { ...oldValues, details: null };
          });
        }
      }
    });
  }, [setUserContext, userContext.token]);

  const fetchMail = (e) => {
    const url = process.env.REACT_APP_API_ENDPOINT + "messaging/message/sent";
    // console.log(userContext.details.firstName + " " + userContext.details.lastName)
    axios({
      url: url,
      params: {
        senderName:
          userContext.details.firstName + " " + userContext.details.lastName,
      },
      method: "GET",
    })
      .then((response) => {
        //   console.log(response.data)
        setData(response.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Sent Items",
        columns: [
          {
            Header: "Date",
            accessor: "messageSendDate",
            accessor: (d) => {
              return Moment(d.messageSendDate).local().format("DD-MM-YYYY");
            },
          },
          {
            Header: "From",
            accessor: "senderName",
          },
          {
            Header: "To",
            accessor: "recipientName",
          },
          {
            Header: "Subject",
            accessor: "subject",
          },
          {
            id: "read",
            Header: "Status",
            accessor: (d) => d.read.toString(),
            Cell: ({ value }) => selectUnreadReadIcon(value),
          },
        ],
      },
    ],
    []
  );

  const selectUnreadReadIcon = (value) => {
    var i = 1;
    //console.log(value)
    switch (value) {
      case "true":
        return (
          <div key={i}>{[<MarkEmailReadIcon color="success" />]} Read</div>
        );
      case "false":
        return (
          <div key={i}>{[<MailIcon sx={{ color: red[900] }} />]} Unread</div>
        );
      default:
        return null;
    }

    // then return read/unread icon
  };

  const defaultColumn = React.useMemo(
    () => ({
      Filter: TextFilter,
    }),
    []
  );

  const sortees = React.useMemo(
    () => [
      {
        id: "messageSendDate",
        desc: false,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageOptions,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy: sortees,
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  function TextFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  }

  const { history } = props;
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleWriteMessageListItemClick = () => {
    history.push({
      pathname: "/compose",
    });
  };
  const handleInboxListItemClick = () => {
    history.push({
      pathname: "/inbox",
    });
  };

  const handleSentListItemClick = () => {
    history.push({
      pathname: "/sent-items",
    });
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openFromParent() {
    setIsOpen(true);
  }

  function handleCloseModal(event, data) {
    setIsOpen(false);
  }

  function handleAfterOpen(event, data) {
    //console.log(event, data);
  }

  return (
    <div>
      <Header></Header>
      <div class="container-fluid body">
        <h1>Messaging</h1>
        <div class="row">
          <div class="col-lg-auto">
            <div>
              <Card>
                <List component="nav" aria-label="main mailbox folders">
                  <Divider></Divider>
                  <ListItemButton
                    selected={selectedIndex === 0}
                    onClick={(event) =>
                      handleWriteMessageListItemClick(event, 1)
                    }
                  >
                    <ListItemIcon>
                      <DraftsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Write Message" />
                  </ListItemButton>

                  <ListItemButton
                    selected={selectedIndex === 0}
                    onClick={(event) => handleInboxListItemClick(event, 1)}
                  >
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Inbox" />
                  </ListItemButton>
                  <ListItemButton
                    selected={selectedIndex === 1}
                    onClick={(event) => handleSentListItemClick(event, 0)}
                  >
                    <ListItemIcon>
                      <SendIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sent Items" />
                  </ListItemButton>
                </List>
              </Card>
            </div>
          </div>
          <div class="col-lg-auto">
            <div className="flex flex-col">
              <br></br>
              <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-gray-100">
                    <Table
                      {...getTableProps()}
                      className="specialCursor min-w-full divide-y divide-gray-200"
                    >
                      <thead className="bg-gray-50">
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th
                                className="px-6 py-3 text-left text-medium font-medium text-gray-500 uppercase tracking-wider"
                                {...column.getHeaderProps()}
                              >
                                {column.render("Header")}
                                <div>
                                  {column.canFilter
                                    ? column.render("Filter")
                                    : null}
                                </div>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <Tbody
                        className="bg-white divide-y divide-gray-200"
                        {...getTableBodyProps()}
                      >
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr
                              {...row.getRowProps()}
                              onClick={() => selectRows(row)}
                            >
                              {row.cells.map((cell) => {
                                return (
                                  <td {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </Tbody>
                    </Table>

                    <SentModal
                      dynData={modalData}
                      IsModalOpened={modalIsOpen}
                      onCloseModal={handleCloseModal}
                      onAfterOpen={handleAfterOpen}
                    />

                    <div className="px-6 py-3 bg-gray-100 text-left text-medium font-medium text-gray-500 uppercase tracking-wider">
                      Page{" "}
                      <em>
                        {pageIndex + 1} of {pageOptions.length}
                      </em>
                      <div className="floatRightWithMargin">
                        <PageButton
                          className="rounded-l-md"
                          onClick={() => previousPage()}
                          disabled={!canPreviousPage}
                        >
                          <ChevronLeftIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                          Previous
                        </PageButton>

                        <PageButton
                          className="rounded-l-md"
                          onClick={() => nextPage()}
                          disabled={!canNextPage}
                        >
                          <ChevronRightIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                          Next
                        </PageButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SentItems;
