import { Col } from "react-bootstrap";
import Modal from "react-modal";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { xwithRouter } from "react-router-dom";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    width: "70%",
    bottom: "auto",
    marginRight: "-50%",
    height: "70%",
    transform: "translate(-50%, -50%)",
    "overflow-y": "scroll",
  },
};

function MyModal(props) {
  // check to see if the messages contains reply info
  let formattedMessage = props.dynData.message.replace(
    /---REPLY BELOW THIS LINE---/g,
    "\n---------------\n Reply:"
  );

  // Original message
  //console.log(formattedMessage);
  const { history } = props;

  function afterOpenModal(e) {
    props.onAfterOpen(e, "After Modal Opened");
  }

  function onModalClose(event) {
    let data = { name: "example", type: "closed from child" };
    props.onCloseModal(event, data);
  }
  const styles = (theme) => ({
    modalStyle1: {
      color: "red",
      top: "50%",
      left: "50%",
      right: "auto",
      width: "70%",
      bottom: "auto",
      overflow: "scroll",
      height: "100%",
      display: "block",
      transform: "translate(-50%, -50%)",
    },
  });

  const [replyData, setReplyData] = useState([]);

  useEffect(() => {
    //console.log("reached use effect");
    setReplyData({
      sender: props.dynData.sender,
      from: props.dynData.sender,
      messageSendDate: props.dynData.messageSendDate,
      subject: props.dynData.subject,
      message: formattedMessage,
    });
  }, [props.onAfterOpen]);

  const redirectToPage = () => {
    history.push({
      pathname: "/compose-reply",
      userProps: {
        // location state
        replyData,
      },
    });
  };

  return (
    <div>
      <Modal
        isOpen={props.IsModalOpened}
        onAfterOpen={(e) => afterOpenModal(e)}
        style={customStyles}
        ariaHideApp={false}
      >
        <button
          type="button"
          className="btn btn-primary m-2"
          minimal
          intent="primary"
          onClick={() => redirectToPage()}
        >
          Reply
        </button>
        <button
          type="button"
          className="btn btn-primary m-2 floatRightWithMargin"
          minimal
          intent="primary"
          onClick={(e) => onModalClose(e)}
        >
          Close Message
        </button>
        <h2>From: {props.dynData.sender}</h2>
        <h6>Date Received: {props.dynData.messageSendDate}</h6>
        <h6>Subject: {props.dynData.subject}</h6>

        <div>
          <br></br>
          <br></br>
          <p className="message-text">{formattedMessage}</p>
        </div>
      </Modal>
    </div>
  );
}

export default withRouter(MyModal);
