import Card from "@mui/material/Card";
import { CardContent } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import axios from "axios";
import Header from "./Header";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";

const ComposeReply = (props) => {
  //console.log(props.location.userProps.replyData);

  const [userContext, setUserContext] = useContext(UserContext);

  const initialFormData = Object.freeze({
    recipientName: props.location.userProps.replyData.sender,
    recipientEmail: "",
    senderName:
      userContext.details.firstName + " " + userContext.details.lastName,
    subject: "RE: " + props.location.userProps.replyData.subject,
    message:
      props.location.userProps.replyData.message +
      "\r\r---REPLY BELOW THIS LINE---",
  });

  const [formData, updateFormData] = React.useState(initialFormData);

  const handleChange = (e) => {
    // console.log(e.target.value);
    updateFormData({
      ...formData,
      // Trimming any whitespace
      message: e.target.value,
    });

    //  console.log(formData);
  };

  const initialEmailData = Object.freeze({
    recipientEmail: "",
  });
  //const [formData, updateFormData] = React.useState(initialFormData);
  const [selectedRecipientEmail, updateSelectedRecipientEmail] =
    React.useState(initialEmailData);

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);

  useEffect(() => {
    if (!userContext.details) {
      fetchUserDetails();
    } else {
      //fetchPhotographerNames();
      // // set data from received props
      // updateFormData(
      //   {
      //     message:
      //   }
      // )
      fetchPhotographerEmail();
    }
  }, []);

  // const handleRecipientChange = (e) => {
  //   //console.log("Reached handle recip change");
  //   // updateFormData({
  //   //   ...formData,

  //   //   // Trimming any whitespace
  //   //   [e.target.name]: e.target.value,
  //   // });

  //   // now look up and return the email address for that receipient
  //   const url =
  //     process.env.REACT_APP_API_ENDPOINT +
  //     "photographers/get-photographer-email";
  //   console.log(e.target.value);
  //   axios({
  //     url: url,
  //     params: {
  //       _id: e.target.value,
  //     },
  //     method: "GET",
  //   })
  //     .then((response) => {
  //       console.log(response.data);
  //       //setData(response.data);

  //       // now we've got the looked up email address, finally set the form data
  //       updateFormData({
  //         recipientName:
  //           response.data[0].firstName + " " + response.data[0].lastName,
  //         recipientEmail: response.data[0].username,
  //         senderName:
  //           userContext.details.firstName + " " + userContext.details.lastName,
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });

  //   console.log(formData);
  //  };

  const fetchUserDetails = useCallback(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "users/me", {
      method: "GET",
      credentials: "include",
      //Pass authentication token as bearer token in header
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userContext.token}`,
      },
    }).then(async (response) => {
      if (response.ok) {
        const data = await response.json();
        setUserContext((oldValues) => {
          return { ...oldValues, details: data };
        });
      } else {
        if (response.status === 401) {
          // Edge case: when the token has expired.
          //This could happen if the refreshToken calls have failed due to network error or
          // User has had the tab open from previous day and tries to click on the Fetch button
          //window.location.reload()
        } else {
          setUserContext((oldValues) => {
            return { ...oldValues, details: null };
          });
        }
      }
    });
  }, [setUserContext, userContext.token]);

  const fetchPhotographerEmail = (e) => {
    //console.log("called fetch photographers email");
    const url =
      process.env.REACT_APP_API_ENDPOINT + "photographers/get-reply-email";

    // console.log(props.location.userProps.replyData.sender);

    // split sender name by the space
    let text = props.location.userProps.replyData.sender;
    const splitArray = text.split(" ");

    axios({
      url: url,
      params: {
        firstName: splitArray[0],
        lastName: splitArray[1],
      },
      method: "GET",
    })
      .then((response) => {
        //console.log(response.data);
        // updateSelectedRecipientEmail({
        //   recipientEmail: response.data[0].username,
        // });

        updateFormData({
          ...formData,
          recipientEmail: response.data[0].username,
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    // console.log(selectedRecipientEmail);
  }, [selectedRecipientEmail]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      console.log(formData);
      // ... submit to API or something

      const url = process.env.REACT_APP_API_ENDPOINT + "messaging/message/add";
      axios.post(url, formData, {}).then((res) => {
        // console.log(res.data)
      });
      setIsSuccessfullySubmitted(true);
      // reset the form
      //e.target.reset();
      // updateFormData({
      //   recipientName: "",
      //   senderName:
      //     userContext.details.firstName + " " + userContext.details.lastName,
      //   subject: "",
      //   message: "",
      // });

      // send the email
      submitEmail();
    },
    [formData]
  );

  const submitEmail = async (e) => {
    //e.preventDefault();
    // console.log({ mailerState });

    const response = await fetch(process.env.REACT_APP_API_ENDPOINT + "send", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ formData }),
    })
      .then((res) => res.json())
      .then(async (res) => {
        const resData = await res;
        //console.log(resData);
        if (resData.status === "success") {
          console.log("Message Sent");
        } else if (resData.status === "fail") {
          console.log("Message failed to send");
        }
      })
      .then(() => {
        updateFormData({
          recipientName: "",
          senderName:
            userContext.details.firstName + " " + userContext.details.lastName,
          subject: "",
          message: "",
        });
      });
  };

  const { history } = props;
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleWriteMessageListItemClick = () => {
    history.push({
      pathname: "/compose",
    });
  };
  const handleInboxListItemClick = () => {
    history.push({
      pathname: "/inbox",
    });
  };

  const handleSentListItemClick = () => {
    history.push({
      pathname: "/sent-items",
    });
  };

  return (
    <div>
      <Header></Header>
      <div class="container-fluid body">
        <h1>Messaging</h1>
        <div class="row">
          <div class="col-lg-auto">
            <Card>
              <List component="nav" aria-label="main mailbox folders">
                <Divider></Divider>
                <ListItemButton
                  selected={selectedIndex === 1}
                  onClick={(event) => handleWriteMessageListItemClick(event, 1)}
                >
                  <ListItemIcon>
                    <DraftsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Write Message" />
                </ListItemButton>

                <ListItemButton
                  selected={selectedIndex === 0}
                  onClick={(event) => handleInboxListItemClick(event, 1)}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Inbox" />
                </ListItemButton>
                <ListItemButton
                  selected={selectedIndex === 0}
                  onClick={(event) => handleSentListItemClick(event, 0)}
                >
                  <ListItemIcon>
                    <SendIcon />
                  </ListItemIcon>
                  <ListItemText primary="Sent Items" />
                </ListItemButton>
              </List>
            </Card>
          </div>
          <div class="col-sm">
            <div className="flex flex-col">
              <Card className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-gray-50">
                <CardContent>
                  <div className="text-left text-medium font-medium text-gray-500 uppercase tracking-wider">
                    <h6>Reply to Message</h6>
                  </div>

                  <form>
                    {" "}
                    <br></br>
                    <div class="form-group">
                      <label for="exampleInputEmail1">To</label>
                      <input
                        type="text"
                        className="form-control"
                        name="senderName"
                        id="exampleInputEmail1"
                        readOnly
                        aria-describedby="emailHelp"
                        value={props.location.userProps.replyData.sender}
                        onChange={handleChange}
                      />
                    </div>
                    <div class="form-group">
                      <label for="exampleInputEmail1">
                        An email notification will be sent to...
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="senderName"
                        id="exampleInputEmail1"
                        readOnly
                        aria-describedby="emailHelp"
                        value={formData.recipientEmail}
                        onChange={handleChange}
                      />
                    </div>
                    <div class="form-group">
                      <label for="exampleInputEmail1">From</label>
                      <input
                        type="text"
                        className="form-control"
                        name="senderName"
                        id="exampleInputEmail1"
                        readOnly
                        aria-describedby="emailHelp"
                        value={
                          userContext.details.firstName +
                          " " +
                          userContext.details.lastName
                        }
                        onChange={handleChange}
                      />
                    </div>
                    <br></br>
                    <div class="form-group">
                      <label for="exampleInputEmail1">Subject</label>
                      <input
                        type="text"
                        class="form-control"
                        name="subject"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={handleChange}
                        value={formData.subject}
                      />
                    </div>
                    <br></br>
                    <div className="form-group ">
                      <label for="exampleInputEmail1">Message</label>
                      <textarea
                        type="text"
                        class="form-control"
                        name="message"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        rows="12"
                        onChange={handleChange}
                        value={formData.message}
                      />
                    </div>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      intent="primary"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                    {isSuccessfullySubmitted && (
                      <div className="success">Message sent successfully</div>
                    )}
                  </form>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComposeReply;
