import Card from "@mui/material/Card";
import { CardContent } from "@mui/material";
import React from "react";
import Header from "../components/Header";
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone";
import { blueGrey, purple } from "@mui/material/colors";

const Schedules = () => {
  return (
    <div>
      <Header></Header>
      <div class="container-fluid body">
        <div class="row">
          <div class="col">
            <div>
              <Card className="mt-3">
                <CardContent>
                  <ErrorTwoToneIcon
                    sx={{ color: purple[800], fontSize: 38 }}
                  ></ErrorTwoToneIcon>
                  &nbsp;&nbsp;Use the grey scrollbars below to scroll the
                  worksheet. Change the displayed week by selecting a "Week" tab
                  at the bottom.
                </CardContent>
              </Card>
              <br></br>
              <iframe
                className="gsheetsViewer"
                title="gsheets"
                src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTujC56akO_7qb1LY6GozzaisenUnGzlGWqz_QwMn-SJjaAxBmbazD6nH6xjFL39xA9fkwE__frEHff/pubhtml?widget=true&amp;headers=false"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Schedules;
