import React, { useContext, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import Loader from "./Loader";
import { ReactSlackChat } from "react-slack-chat";
import { NavLink, useHistory } from "react-router-dom";
import logo from "../images/cf_white (Custom).png";
import {
  faHome,
  faCalendarDays,
  faFile,
  faComments,
  faCloudArrowUp,
  faGraduationCap,
  faSignal,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import fcdLogo from "../images/fcd_logo.png";

const Footer = (props) => {
  // store props value in local storage
  const [userContext, setUserContext] = useContext(UserContext);
  //const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const history = useHistory();

  useEffect(() => {
    // fetch only when user details are not present
    // console.log("Reached fetch mail in the Header")
    // fetchMail();
  }, []);

  const handlePrivacyPolicyClick = () => {
    history.push({
      pathname: "/privacy-policy",
    });
  };

  const handleCookiePolicyClick = () => {
    history.push({
      pathname: "/cookie-policy",
    });
  };

  return (
    <div>
      <footer>
        <p>
          <button
            className={"button-styled-as-link"}
            onClick={handlePrivacyPolicyClick}
          >
            Privacy Policy
          </button>
          &nbsp;&nbsp;| &nbsp;&nbsp;
          <button
            className={"button-styled-as-link"}
            onClick={handleCookiePolicyClick}
          >
            Cookie Policy
          </button>
        </p>
        Design by Adam Fletcher
        <img src={fcdLogo} alt="fcd_logo" className="fcd-logo"></img>
      </footer>
    </div>
  );
};
export default Footer;
