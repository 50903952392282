import Card from "@mui/material/Card";
import React from "react";
import SinglePagePDFViewer from "../components/pdf/single-page";
import dataPDF from "../documents/data_manual_23.pdf";
import groupsPDF from "../documents/groups_manual_23.pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import Header from "../components/Header";
import { CardContent } from "@mui/material";

const Resources = () => {
  return (
    <div>
      <Header></Header>
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm">
            <div className="user-details">
              <h4>Data Manual (Last revised: Spring 2023)</h4>
              <div>
                <SinglePagePDFViewer pdf={dataPDF}></SinglePagePDFViewer>
              </div>
            </div>
          </div>
          <div class="col-sm">
            <div class="user-details">
              <h4>Groups Manual (Last revised: Spring 2023)</h4>
              <div className="all-page-container">
                <SinglePagePDFViewer pdf={groupsPDF}></SinglePagePDFViewer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resources;
