import Card from "@mui/material/Card";
import { CardContent } from "@mui/material";
import React, { useCallback, useContext, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { NavLink } from "react-router-dom";
import feedbackIcon from "../images/home_feedback_icon.png";
import Header from "../components/Header";
const Feedback = () => {
  // const navigate = useNavigate();
  // const navigateTo = () => {
  //     // 👇️ navigate to /
  //     navigate('/schedules');
  // };

  // -=-=-=- User Context Code -=-=-=-=-=--=
  const [userContext, setUserContext] = useContext(UserContext);

  const fetchUserDetails = useCallback(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "users/me", {
      method: "GET",
      credentials: "include",
      //Pass authentication token as bearer token in header
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userContext.token}`,
      },
    }).then(async (response) => {
      if (response.ok) {
        const data = await response.json();
        setUserContext((oldValues) => {
          return { ...oldValues, details: data };
        });
      } else {
        if (response.status === 401) {
          // Edge case: when the token has expired.
          //This could happen if the refreshToken calls have failed due to network error or
          // User has had the tab open from previous day and tries to click on the Fetch button
          //window.location.reload()
        } else {
          setUserContext((oldValues) => {
            return { ...oldValues, details: null };
          });
        }
      }
    });
  }, [setUserContext, userContext.token]);

  useEffect(() => {
    // fetch only when user details are not present
    if (!userContext.details) {
      fetchUserDetails();
    }
  }, [userContext.details, fetchUserDetails]);

  const refetchHandler = () => {
    //  set details to undefined so that spinner will be displayed and
    // fetchUserDetails will be invoked from useEffect
    setUserContext((oldValues) => {
      return { ...oldValues, details: undefined };
    });
  };
  // -=-=-=- END User Context Code -=-=-=-=-=--=

  const renderSwitch = () => {
    //console.log(userContext.details.team)
    switch (userContext.details.team) {
      case "Photographers":
        return (
          <div class="container-fluid body">
            <div class="row">
              <div class="col">
                <h1>Feedback Actions</h1>

                <Card>
                  <CardContent>
                    <img src={feedbackIcon} alt="feedback_icon" />
                    <h6>Photographers - View your feedback from the lab</h6>
                    <NavLink to="/view-feedback">
                      {" "}
                      <button
                        type="button"
                        className="btn btn-primary m-2"
                        minimal
                        intent="primary"
                      >
                        View feedback
                      </button>
                    </NavLink>
                  </CardContent>
                </Card>
              </div>

              <div class="col"></div>
            </div>
          </div>
        );

      case "Lab":
        return (
          <div class="container-fluid body">
            <div class="row">
              <div class="col">
                <h1>Feedback Actions</h1>
                <div>
                  <Card>
                    <CardContent>
                      <img src={feedbackIcon} alt="feedback_icon" />
                      <h6>Lab</h6>
                      <p>Leave feedback for a photographer</p>
                      <NavLink to="/enter-feedback">
                        {" "}
                        <button
                          type="button"
                          className="btn btn-primary m-2"
                          minimal
                          intent="primary"
                        >
                          Leave feedback
                        </button>
                      </NavLink>
                    </CardContent>
                  </Card>
                </div>
              </div>
              <div class="col">
                <div className="medMargin">
                  <Card>
                    <CardContent>
                      <img src={feedbackIcon} alt="feedback_icon" />
                      <h6>Photographers</h6>
                      <p>View your feedback from the lab</p>
                      <NavLink to="/view-feedback">
                        {" "}
                        <button
                          type="button"
                          className="btn btn-primary m-2"
                          minimal
                          intent="primary"
                        >
                          View feedback
                        </button>
                      </NavLink>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        );

      case "Head Office":
        return (
          <div class="container-fluid body">
            <div class="row">
              <div class="col">
                <h1>Feedback Actions</h1>
              </div>
              <div class="col">
                <div className="medMargin">
                  <Card>
                    <CardContent>
                      <img src={feedbackIcon} alt="feedback_icon" />
                      <h6>Photographers & Head Office</h6>
                      <p>View feedback from the lab</p>
                      <NavLink to="/view-feedback">
                        {" "}
                        <button
                          type="button"
                          className="btn btn-primary m-2"
                          minimal
                          intent="primary"
                        >
                          View feedback
                        </button>
                      </NavLink>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return (
          <div class="container-fluid body body">
            <div class="row">
              <div class="col">
                <h1>Feedback Actions</h1>
                <div>
                  <Card>
                    <CardContent>
                      <h6>Lab - Leave feedback for a photographer</h6>
                      <NavLink to="/enter-feedback">
                        {" "}
                        <button
                          type="button"
                          className="btn btn-primary m-2"
                          minimal
                          intent="primary"
                        >
                          Leave feedback
                        </button>
                      </NavLink>
                    </CardContent>
                  </Card>
                </div>
              </div>
              <div class="col">
                <div className="medMargin">
                  <Card>
                    <CardContent>
                      <h6>Photographers - View your feedback from the lab</h6>
                      <NavLink to="/view-feedback">
                        {" "}
                        <button
                          type="button"
                          className="btn btn-primary m-2"
                          minimal
                          intent="primary"
                        >
                          View feedback
                        </button>
                      </NavLink>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  return userContext.details === null ? (
    "Error Loading User details"
  ) : (
    <div>
      <Header></Header>
      {renderSwitch()}
    </div>
  );
};

export default Feedback;
