import Alert from '@mui/material/Alert';
import React, { useContext, useState } from "react"
import { UserContext } from "../context/UserContext"

const Register = () => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [error, setError] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [secPasscode, setPasscode] = useState("")
    const [team, setTeam] = useState("")
    const [userContext, setUserContext] = useContext(UserContext)

    const handleChange = (e) => {
        //console.log(e.target.value)
        setTeam(e.target.value);
    };

    const formSubmitHandler = e => {
        e.preventDefault()
        setIsSubmitting(true)
        setError("")
        //console.log(team)
        const genericErrorMessage = "Something went wrong! Please try again later."

        fetch(process.env.REACT_APP_API_ENDPOINT + "users/signup", {
            method: "POST",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ firstName, lastName, username: email, password, team, secPasscode }),
        })
            .then(async response => {
                setIsSubmitting(false)
                if (!response.ok) {
                    if (response.status === 400) {
                        setError("Please fill all the fields correctly!")
                    } else if (response.status === 401) {
                        setError("Invalid email and password combination.")
                    } else if (response.status === 500) {
                        //console.log(response)
                        const data = await response.json()
                        if (data.message) setError(data.message || genericErrorMessage)
                    } else {
                        setError(genericErrorMessage)
                    }
                } else {
                    const data = await response.json()
                    setUserContext(oldValues => {
                        return { ...oldValues, token: data.token }
                    })

                }
            })
            .catch(error => {
                setIsSubmitting(false)
                setError(genericErrorMessage)
            })
    }


    return (
        <>
            {error && <Alert severity='error'>{error}</Alert>}
            Please fill out the required information

            <br></br>
            <div className="form-group">
            <br></br>
            <form onSubmit={formSubmitHandler}>
                <div className="form-group">                
                    <input
                        id="firstName"
                        className="form-control"
                        placeholder='Firstname'
                        onChange={e => setFirstName(e.target.value)}
                        value={firstName}
                    />
                </div>
                <br></br>
                <div className='form-group'>                
                    <input
                        id="lastName"
                        className="form-control"
                        placeholder='Lastname'
                        onChange={e => setLastName(e.target.value)}
                        value={lastName}
                    />
                </div>
                <br></br>
                <div className='form-group'>
                    <input
                        id="email"
                        className="form-control"
                        placeholder='Email'
                        type="email"
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                    />
                </div>
                <br></br>
                <div className='form-group'>
                    <input
                        id="password"
                        className="form-control"
                        placeholder='Password'
                        type="password"
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                    />
                </div>
                <br></br>
                <div className='form-group'>
                    <select name="team" id="team" className="form-control" onChange={e => setTeam(e.target.value)}>
                        <option disabled={false} value="" id="test">
                            --Choose your team--
                        </option>
                        <option disabled={false} value="Photographers" id="test">
                            Photographers
                        </option>
                        <option disabled={false} value="Lab" id="test">
                            Lab
                        </option>
                        <option disabled={false} value="Head Office" id="test">
                            Head Office
                        </option>
                        <option disabled={false} value="Customer Services" id="test">
                            Customer Services
                        </option>
                    </select>
                    </div><br></br>
                <div className='form-group'>
                <input
                        id="secPasscode"
                        type="passcode"
                        placeholder='Security Passcode'
                        className="form-control"
                        onChange={e => setPasscode(e.target.value)}
                        value={secPasscode}
                    />
                </div>
                <br></br>
                <button
                    intent="primary"
                    className="btn btn-outline-primary"
                    disabled={isSubmitting}
                    text={`${isSubmitting ? "Registering" : "Register"}`}
                    fill
                    type="submit"
                >Register</button>
            </form>
            </div>
        </>
    )
}

export default Register