import React from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    width: "70%",
    bottom: "auto",
    marginRight: "-50%",
    height: "70%",
    transform: "translate(-50%, -50%)",
    "overflow-y": "scroll",
  },
};

function SentModal(props) {
  function afterOpenModal(e) {
    props.onAfterOpen(e, "After Modal Opened");
  }

  function onModalClose(event) {
    let data = { name: "example", type: "closed from child" };
    props.onCloseModal(event, data);
  }

  return (
    <div>
      <Modal
        isOpen={props.IsModalOpened}
        onAfterOpen={(e) => afterOpenModal(e)}
        style={customStyles}
        ariaHideApp={false}
      >
        <button
          type="button"
          className="btn btn-primary m-2 floatRightWithMargin"
          minimal
          intent="primary"
          onClick={(e) => onModalClose(e)}
        >
          Close Message
        </button>

        <h2>To: {props.dynData.recipient}</h2>
        <h6>Date Sent: {props.dynData.messageSendDate}</h6>
        <h6>Subject: {props.dynData.subject}</h6>

        <div>
          <br></br>
          <br></br>
          <p className="message-text">{props.dynData.message}</p>
        </div>
      </Modal>
    </div>
  );
}

export default SentModal;
