import React, {
  useCallback,
  useContext,
  useEffect,
  Component,
  useState,
} from "react";
import { UserContext } from "../context/UserContext";
import Header from "./Header";
import axios from "axios";
import ratingOne from "../images/ratingOne.png";
import ratingTwo from "../images/ratingTwo.png";
import ratingThree from "../images/ratingThree.png";
import ratingFour from "../images/ratingFour.png";
import ratingFive from "../images/ratingFive.png";
import MailIcon from "@mui/icons-material/Mail";
import { useTable, usePagination, useSortBy } from "react-table";
import { red } from "@mui/material/colors";
import Alert from "@mui/material/Alert";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { PageButton } from "../components/Button";
import { Table, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

const ManagementStats = () => {
  const [userContext, setUserContext] = useContext(UserContext);
  const [photographerNames, setPhotographersNames] = useState("");
  const [ratingFiveCount, setRatingFiveCount] = useState(0);
  const [ratingFourCount, setRatingFourCount] = useState(0);
  const [ratingThreeCount, setRatingThreeCount] = useState(0);
  const [ratingTwoCount, setRatingTwoCount] = useState(0);
  const [ratingOneCount, setRatingOneCount] = useState(0);

  const initialFormData = Object.freeze({
    photographerName: "",
    averageFeedbackScore: 0,
    ratingFive: 0,
    ratingFour: 0,
    ratingThree: 0,
    ratingTwo: 0,
    ratingOne: 0,
    unreadCount: 0,
  });

  const [data, setData] = React.useState([initialFormData]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Management Stats - Photography Team",
        columns: [
          {
            Header: "Photographer Name",
            accessor: "photographerName",
          },
          {
            Header: "Average Feedback Score",
            accessor: "averageFeedbackRating",
            textAlign: "center",
            Cell: ({ value }) => formatPercentage(value),
          },
          {
            Header: "Feedback Received",
            accessor: "feedbackReceived",
          },

          {
            Header: ({ value }) => selectFeedbackFace(5),
            accessor: "excellentFeedback",
          },
          {
            Header: ({ value }) => selectFeedbackFace(4),
            accessor: "goodFeedback",
          },
          {
            Header: ({ value }) => selectFeedbackFace(3),
            accessor: "averageFeedback",
          },
          {
            Header: ({ value }) => selectFeedbackFace(2),
            accessor: "badFeedback",
          },
          {
            Header: ({ value }) => selectFeedbackFace(1),
            accessor: "poorFeedback",
          },
          {
            Header: ({ value }) => selectFeedbackFace(6),
            accessor: "unreadCount",
          },
        ],
      },
    ],
    []
  );

  const calculateTotals = (value) => {
    let totals = 0;

    totals =
      ratingOneCount +
      ratingTwoCount +
      ratingThreeCount +
      ratingFourCount +
      ratingFiveCount;

    return totals;
  };

  const formatPercentage = (value) => {
    let formatted;
    if (value) {
      let converted = Math.round(value);
      formatted = converted === 0 ? "-" : `${converted}%`;
    } else {
      formatted = "-";
    }
    return formatted;
  };

  const selectFeedbackFace = (value) => {
    var i = 1;

    switch (value) {
      case 1:
        return (
          <div key={i}>{[<img src={ratingOne} alt="unhappy_face" />]} Poor</div>
        );
      case 2:
        return (
          <div key={i}>
            {[<img src={ratingTwo} alt="discontented_face" />]} Bad
          </div>
        );
      case 3:
        return (
          <div key={i}>{[<img src={ratingThree} alt="neutral_face" />]} OK</div>
        );
      case 4:
        return (
          <div key={i}>{[<img src={ratingFour} alt="happy_face" />]} Good</div>
        );
      case 5:
        return (
          <div key={i}>
            {[<img src={ratingFive} alt="ecstatic_face" />]} Outstanding
          </div>
        );
      case 6:
        return (
          <div key={i}>
            {[<MailIcon sx={{ color: red[900] }} />]} Unread Feedback
          </div>
        );
      default:
        return null;
    }

    // then return read/unread icon
  };

  function renderHtml() {
    return (
      <div>
        <h1>I want this to render on click</h1>
      </div>
    );
  }

  const sortees = React.useMemo(
    () => [
      {
        id: "averageFeedbackRating",
        desc: true,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageOptions,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: sortees,
        pageSize: 30,
      },
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    //  console.log("Reached useEffect");
    // fetch only when user details are not present
    if (!userContext.details) {
      // fetchUserDetails()
      //console.log(userContext.firstName)
    } else {
      fetchFeedbackStats();
    }
  }, [userContext.details]);

  const fetchFeedbackStats = () => {
    //console.log("Called fetch feedbackStats");
    // console.log(userContext.details.team)
    switch (userContext.details.team) {
      case "Photographers":
        const url =
          process.env.REACT_APP_API_ENDPOINT + "feedback/record/viewAllStats";
        // console.log(userContext.details.firstName + " " + userContext.details.lastName)
        axios({
          url: url,
          method: "GET",
        })
          .then((response) => {
            //  console.log(response.data)
            for (let i = 0; i <= response.length; i++)
              if (i <= 4) {
                setData(response.data);
              }
          })
          .catch((err) => {
            console.log(err.message);
          });
        break;

      case "Lab":
        const url2 =
          process.env.REACT_APP_API_ENDPOINT + "feedback/record/viewAllStats";
        // console.log(userContext.details.firstName + " " + userContext.details.lastName)
        axios({
          url: url2,
          method: "GET",
        })
          .then((response) => {
            setData(response.data);
          })
          .catch((err) => {
            console.log(err.message);
          });
        break;
      case "Head Office":
        const url3 =
          process.env.REACT_APP_API_ENDPOINT + "feedback/record/viewAllStats";
        //   console.log(userContext.details.firstName + " " + userContext.details.lastName)
        axios({
          url: url3,
          method: "GET",
        })
          .then((response) => {
            //       console.log(response.data)
            setData(response.data);
          })
          .catch((err) => {
            console.log(err.message);
          });
        break;
      default:
        const url4 =
          process.env.REACT_APP_API_ENDPOINT + "feedback/record/viewAllStats";
        // console.log(userContext.details.firstName + " " + userContext.details.lastName)
        axios({
          url: url4,
          method: "GET",
        })
          .then((response) => {
            //         console.log(response.data)
            setData(response.data);
          })
          .catch((err) => {
            console.log(err.message);
          });
        break;
    }
  };

  // const defaultColumn = React.useMemo(
  //     () => ({
  //         Filter: TextFilter,
  //     }),
  //     []
  // )

  // const sortees = React.useMemo(
  //     () => [
  //         {
  //             id: "shootDate",
  //             desc: false
  //         }
  //     ],
  //     []
  // );

  // const {
  //     getTableProps,
  //     getTableBodyProps,
  //     headerGroups,
  //     rows,
  //     prepareRow,
  //     page,
  //     pageOptions,
  //     state: { pageIndex, pageSize },
  //     previousPage,
  //     nextPage,
  //     canPreviousPage,
  //     canNextPage,
  // } = useTable({
  //     columns, formData, defaultColumn, initialState: {
  //         sortBy: sortees,
  //     }
  // }, useFilters, useSortBy, usePagination)

  // function TextFilter({
  //     column: { filterValue, preFilteredRows, setFilter },
  // }) {
  //     const count = preFilteredRows.length

  //     return (
  //         <input
  //             value={filterValue || ''}
  //             onChange={e => {
  //                 setFilter(e.target.value || undefined)
  //             }}
  //             placeholder={`Search ${count} records...`}
  //         />
  //     )
  // }
  const renderSwitch = () => {
    switch (userContext.details.team) {
      case "Head Office":
        return (
          <div>
            <div class="container-fluid body">
              <div class="row">
                <div class="col">
                  <br></br>

                  <table
                    {...getTableProps()}
                    className="specialCursor min-w-full divide-y divide-gray-200 "
                  >
                    <thead className="bg-gray-50">
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              className="px-6 py-3 text-left text-medium font-medium text-gray-500 uppercase tracking-wider "
                              {...column.getHeaderProps()}
                            >
                              {column.render("Header")}
                              <div>
                                {column.canFilter
                                  ? column.render("Filter")
                                  : null}
                              </div>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody
                      className="bg-white divide-y divide-gray-200"
                      {...getTableBodyProps()}
                    >
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="px-6 py-3 bg-gray-100 text-left text-medium font-medium text-gray-500 uppercase tracking-wider">
                    Page{" "}
                    <em>
                      {pageIndex + 1} of {pageOptions.length}
                    </em>
                    <div className="floatRightWithMargin">
                      <PageButton
                        className="rounded-l-md"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                      >
                        <ChevronLeftIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                        Previous
                      </PageButton>

                      <PageButton
                        className="rounded-l-md"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                      >
                        <ChevronRightIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                        Next
                      </PageButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "Lab":
        return (
          <div>
            <div class="container-fluid body">
              <div class="row">
                <div class="col">
                  <br></br>

                  <Table
                    {...getTableProps()}
                    className="specialCursor min-w-full divide-y divide-gray-200 stats-table"
                  >
                    <thead className="bg-gray-50">
                      {headerGroups.map((headerGroup) => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <Th
                              className="px-6 py-3 text-left text-medium font-medium text-gray-500 uppercase tracking-wider"
                              {...column.getHeaderProps()}
                            >
                              {column.render("Header")}
                              <div>
                                {column.canFilter
                                  ? column.render("Filter")
                                  : null}
                              </div>
                            </Th>
                          ))}
                        </Tr>
                      ))}
                    </thead>
                    <Tbody
                      className="bg-white divide-y divide-gray-200"
                      {...getTableBodyProps()}
                    >
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <Tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                  <div className="px-6 py-8 bg-gray-100 text-left text-medium font-medium text-gray-500 uppercase tracking-wider">
                    Page{" "}
                    <em>
                      {pageIndex + 1} of {pageOptions.length}
                    </em>
                    <div className="floatRightWithMargin">
                      <PageButton
                        className="rounded-l-md"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                      >
                        <ChevronLeftIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                        Previous
                      </PageButton>

                      <PageButton
                        className="rounded-l-md"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                      >
                        <ChevronRightIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                        Next
                      </PageButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      case "Photographers":
        return (
          <div>
            <div class="container-fluid body body">
              <div class="row">
                <div class="col-12">
                  <br></br>
                  <Alert severity="error">
                    Access Denied - You are on the Photography team. Only Head
                    Office accounts can review the table of photographer
                    averages at the moment
                  </Alert>
                </div>
                <div class="col">
                  <div className="medMargin"></div>
                </div>

                <div class="col"></div>
              </div>
            </div>
          </div>
        );

      default:
        return (
          <div>
            <div class="container-fluid body body">
              <div class="row">
                <div class="col">
                  <br></br>
                  <Alert severity="error">Stats failed to load</Alert>
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  return userContext.details === null ? (
    "Error Loading User details"
  ) : (
    <div>
      <Header></Header>
      {renderSwitch()}
    </div>
  );
};

export default ManagementStats;
