import Card from "@mui/material/Card";
import { CardContent } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import FilemailWidget from "../components/Filemail";
import warningIcon from "../images/warningIcon.png";
import Header from "../components/Header";
import axios from "axios";
import Alert from "@mui/material/Alert";

const TransferWork = () => {
  const [userContext, setUserContext] = useContext(UserContext);
  const [numberFeedbackUnread, setNumberFeedbackUnread] = useState(0);

  const fetchUserDetails = useCallback(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "users/me", {
      method: "GET",
      credentials: "include",
      //Pass authentication token as bearer token in header
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userContext.token}`,
      },
    }).then(async (response) => {
      if (response.ok) {
        const data = await response.json();
        setUserContext((oldValues) => {
          return { ...oldValues, details: data };
        });
      } else {
        if (response.status === 401) {
          // Edge case: when the token has expired.
          //This could happen if the refreshToken calls have failed due to network error or
          // User has had the tab open from previous day and tries to click on the Fetch button
          //window.location.reload()
        } else {
          setUserContext((oldValues) => {
            return { ...oldValues, details: null };
          });
        }
      }
    });
  }, [setUserContext, userContext.token]);

  useEffect(() => {
    // fetch only when user details are not present
    if (!userContext.details) {
      fetchUserDetails();
    } else {
      fetchAverages();
    }
  }, [userContext.details, fetchUserDetails]);

  let responseArray = [];

  const fetchAverages = useCallback(() => {
    // console.log("Called fetch averages")
    switch (userContext.details.team) {
      case "Photographers":
        const url = process.env.REACT_APP_API_ENDPOINT + "feedback/record/view";
        // console.log(userContext.details.firstName + " " + userContext.details.lastName)
        axios({
          url: url,
          params: {
            photographerName:
              userContext.details.firstName +
              " " +
              userContext.details.lastName,
          },
          method: "GET",
        })
          .then((response) => {
            let numberFeedbackUnread = 0;

            for (let i = 0; i < response.data.length; i++) {
              if (response.data[i].feedbackRead === false) {
                numberFeedbackUnread++;
              }
            }

            setNumberFeedbackUnread(numberFeedbackUnread);
          })
          .catch((err) => {
            console.log(err.message);
          });
        break;

      default:
        const url4 =
          process.env.REACT_APP_API_ENDPOINT + "feedback/record/view";
        // console.log(userContext.details.firstName + " " + userContext.details.lastName)
        axios({
          url: url4,
          params: {
            photographerName:
              userContext.details.firstName +
              " " +
              userContext.details.lastName,
          },
          method: "GET",
        })
          .then((response) => {
            console.log(response.data);
            // setTasksData(response.data);
          })
          .catch((err) => {
            console.log(err.message);
          });
        break;
    }
  });

  const checkFeedbackAmount = useCallback(() => {
    //console.log("Called fetch tasks")
    if (numberFeedbackUnread >= 10) {
      return (
        <div>
          <Alert severity="error">
            The uploader has been temporarily been blocked for your account
          </Alert>
          <br></br>
          <p>
            The amount of unread feedback for your user account is too high.
            Please read your feedback - it's to ensure we all get heard and
            improve as a team.
          </p>
          <p>
            Feel free to try and use the uploader again after reading some
            feedback.
          </p>
        </div>
      );
    } else {
      return (
        <div data-filemail-widget="uploader" data-company-id="4082148203">
          <FilemailWidget />
        </div>
      );
    }
  });

  return (
    <div>
      <Header></Header>
      <div class="container-fluid body">
        <div class="row">
          <div class="col-sm">
            <div className="user-details">
              <Card>
                <CardContent>
                  <img src={warningIcon} alt="warning_icon" />
                  <p>
                    When you transfer your work to the lab, you{" "}
                    <strong>MUST</strong> ensure that you include:
                  </p>
                  <ul>
                    <li>
                      A photo of the <strong>FRONT and BACK</strong> of your
                      paperwork
                    </li>
                    <li>
                      Your <strong>UNEDITED and EDITED</strong> images
                    </li>
                    <li>
                      A photo of any <strong>staff sheets</strong>
                    </li>
                  </ul>
                  <p>
                    You can then hand in the physical paperwork to Sara when you
                    are next in the lab.
                  </p>
                  <h6>
                    <strong>
                      If you fail to do this, the uploader will be blocked for
                      your user account.
                    </strong>
                  </h6>
                </CardContent>
              </Card>
              <br></br>
              <Card>
                <CardContent>
                  <ol>
                    <h1>Instructions</h1>
                    <br></br>
                    <li>Enter your email address in the "From" field</li>
                    <br></br>
                    <li>
                      The "Subject" field should contain the name of the school
                      and the date of the shoot
                    </li>
                    <br></br>
                    <li>
                      The message field can be used to enter any additional
                      information if required, or you can drop a folder on to it
                      to include it in the transfer
                    </li>
                    <br></br>
                    <li>
                      Use the add folder button to attach your folder of images
                    </li>
                    <br></br>
                    <li>Press send!</li>
                    <br></br>
                    <li>
                      You will receive an email confirmation and a further email
                      once the photos have been downloaded by the lab
                    </li>
                  </ol>
                </CardContent>
              </Card>
            </div>
          </div>
          <div class="col-lg">
            <h1>Send your work to the lab</h1>

            {checkFeedbackAmount()}
          </div>
          <div class="col"></div>
        </div>
      </div>
    </div>
  );
};

export default TransferWork;
