import Card from "@mui/material/Card";
import { CardContent } from "@mui/material";
import React, { useCallback, useContext, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { NavLink } from "react-router-dom";
import feedbackIcon from "../images/home_feedback_icon.png";
import Header from "../components/Header";
import csvIcon from "../images/csv_icon.png";
import pdfIcon from "../images/pdf_icon.png";

const Files = () => {
  // const navigate = useNavigate();
  // const navigateTo = () => {
  //     // 👇️ navigate to /
  //     navigate('/schedules');
  // };

  // -=-=-=- User Context Code -=-=-=-=-=--=
  const [userContext, setUserContext] = useContext(UserContext);

  const fetchUserDetails = useCallback(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "users/me", {
      method: "GET",
      credentials: "include",
      //Pass authentication token as bearer token in header
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userContext.token}`,
      },
    }).then(async (response) => {
      if (response.ok) {
        const data = await response.json();
        setUserContext((oldValues) => {
          return { ...oldValues, details: data };
        });
      } else {
        if (response.status === 401) {
          // Edge case: when the token has expired.
          //This could happen if the refreshToken calls have failed due to network error or
          // User has had the tab open from previous day and tries to click on the Fetch button
          //window.location.reload()
        } else {
          setUserContext((oldValues) => {
            return { ...oldValues, details: null };
          });
        }
      }
    });
  }, [setUserContext, userContext.token]);

  useEffect(() => {
    // fetch only when user details are not present
    if (!userContext.details) {
      fetchUserDetails();
    }
  }, [userContext.details, fetchUserDetails]);

  const refetchHandler = () => {
    //  set details to undefined so that spinner will be displayed and
    // fetchUserDetails will be invoked from useEffect
    setUserContext((oldValues) => {
      return { ...oldValues, details: undefined };
    });
  };
  // -=-=-=- END User Context Code -=-=-=-=-=--=

  return userContext.details === null ? (
    "Error Loading User details"
  ) : (
    <div>
      <Header></Header>
      <div class="container-fluid body body">
        <div class="row">
          <div class="col">
            <h1>Files Menu</h1>
            <div>
              <Card>
                <CardContent>
                  <img
                    src={csvIcon}
                    alt="spreadsheet"
                    className="homeIcons"
                  ></img>
                  <br></br>
                  <br></br>
                  <h6>Data files for Control My Nikon</h6>

                  <NavLink to="/student-data-files">
                    {" "}
                    <button
                      type="button"
                      className="btn btn-primary m-2"
                      minimal
                      intent="primary"
                    >
                      View data files
                    </button>
                  </NavLink>
                </CardContent>
              </Card>
            </div>
          </div>
          <div class="col">
            <div className="medMargin">
              <Card>
                <CardContent>
                  <img
                    src={pdfIcon}
                    alt="spreadsheet"
                    className="homeIcons"
                  ></img>
                  <br></br>
                  <br></br>
                  <h6>Data cards to print at home</h6>
                  <NavLink to="/student-card-files">
                    {" "}
                    <button
                      type="button"
                      className="btn btn-primary m-2"
                      minimal
                      intent="primary"
                    >
                      View data cards
                    </button>
                  </NavLink>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Files;
